import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import './style.less'
import { connect } from 'react-redux'
import { Popup, SwitchButton, Button } from '@valerahealth/ui-core'
import { careManagerApi } from '@valerahealth/rtk-query'
import ReCAPTCHA from 'react-google-recaptcha'
import dStrings from '../../strings.json'
import ProfilePicture from '../common/Picture'
import EditUserProfilePopup from './EditUserProfilePopup'
import { editSettings, editOOO } from '../../redux/actions/globalActions'
import { getProfileName } from '../utilities/treatments'
import { defaultTimeZone, notifyByEnum } from '../utilities/enums'
import EmailIcon from '../../images/notifications_email.svg'
import SMSIcon from '../../images/notifications_sms.svg'
import EditDatesPopup from './EditDatesPopup'
import { loadProgramProviders } from '../../redux/actions/programActions'
import OutOfOffice from '../OutOfOffice'

function NotificationsTable({
  editMode,
  handleChangeData,
  data = {
    alert: {
      email: false,
      text: false,
    },
    digest: {
      email: false,
      text: false,
    },
  },
}) {
  return (
    <table className="notifications-preferences-table">
      <tbody>
        <tr>
          <td />
          <td>
            <EmailIcon />
            {dStrings.email}
          </td>
          <td>
            <SMSIcon />
            {dStrings.text}
          </td>
        </tr>
        <tr>
          <td>
            <b>{dStrings.clinicalAlerts}</b>
          </td>
        </tr>
        <tr>
          <td className="notifications-description">{dStrings.notifyMe}</td>
          <td>
            {editMode ? (
              <SwitchButton
                value={data.alert.email}
                handlerGetData={(e) => {
                  // debugger
                  handleChangeData({
                    ...data,
                    alert: {
                      email: e.target.checked,
                      text: data.alert.text,
                    },
                  })
                }}
              />
            ) : (
              <input disabled type="checkbox" checked={data.alert.email} />
            )}
          </td>
          <td>
            {editMode ? (
              <SwitchButton
                value={data.alert.text}
                handlerGetData={(e) => {
                  handleChangeData({
                    ...data,
                    alert: {
                      email: data.alert.email,
                      text: e.target.checked,
                    },
                  })
                }}
              />
            ) : (
              <input disabled type="checkbox" checked={data.alert.text} />
            )}
          </td>
        </tr>
        <tr>
          <td>
            <b>{dStrings.dailyDigest}</b>
          </td>
        </tr>
        <tr>
          <td className="notifications-description">{dStrings.sendMe}</td>
          <td>
            {editMode ? (
              <SwitchButton
                value={data.digest.email}
                handlerGetData={(e) => {
                  handleChangeData({
                    ...data,
                    digest: {
                      email: e.target.checked,
                      text: data.digest.text,
                    },
                  })
                }}
              />
            ) : (
              <input disabled type="checkbox" checked={data.digest.email} />
            )}
          </td>
          <td>
            {editMode ? (
              <SwitchButton
                value={data.digest.text}
                handlerGetData={(e) => {
                  handleChangeData({
                    ...data,
                    digest: {
                      email: data.digest.email,
                      text: e.target.checked,
                    },
                  })
                }}
              />
            ) : (
              <input disabled type="checkbox" checked={data.digest.text} />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

function NotificationsPopup({
  handleClose,
  handleSave,
  notifications,
  disableSaveBtn,
}) {
  const [data, setData] = useState(notifications)

  const captchaKey = process.env.PROJECT_HIDDEN_CAPTCHA_KEY
  const recaptchaRef = useRef(null)

  return (
    <Popup
      title={dStrings.editOfflineNotifications}
      onClosed={handleClose}
      buttons={[
        {
          text: dStrings.cancel,
          type: 'link',
          onClick: () => {
            handleClose()
          },
        },
        {
          text: dStrings.save,
          type: 'primary',
          disable: disableSaveBtn,
          onClick: () => {
            recaptchaRef.current.execute()
          },
        },
      ]}
    >
      <>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={captchaKey}
          onChange={() => {
            const recaptchaValue = recaptchaRef.current.getValue()
            if (recaptchaValue && handleSave) handleSave(data, recaptchaValue)
          }}
        />
        <NotificationsTable editMode data={data} handleChangeData={setData} />
      </>
    </Popup>
  )
}
function UserProfile({
  user,
  notifications,
  providers,
  programTimeZone,
  editSettings,
  loadProgramProviders,
}) {
  // do not remove: originally this query was performed within middleware
  // care manager api is largely deprecated, but legacy code still require data from this query
  careManagerApi.useGetUserSettingsQuery()

  const [profile, setProfile] = useState((user && user.profile) || {})
  const [displayEditPopup, setDisplayEditPopup] = useState(false)
  const [displayEditDatePopup, setDisplayEditDatePopup] = useState(false)
  const [displayPreferencesPopup, setDisplayPreferencesPopup] = useState(false)
  const [invalidForm, setInvalidForm] = useState()
  const [disabledSaveNotificationsBtn, setDisabledSaveNotificationsBtn] =
    useState(false)
  const [disabledSaveProfileBtn, setDisabledSaveProfileBtn] = useState(false)

  const getNotifyBy = (key) => {
    if (key.text && key.email)
      return { active: true, notifyBy: notifyByEnum.ALL }

    if (key.text) return { active: true, notifyBy: notifyByEnum.SMS }
    if (key.email) return { active: true, notifyBy: notifyByEnum.EMAIL }

    return {
      active: false,
    }
  }

  useEffect(() => {
    setProfile((user && user.profile) || {})
  }, [user])

  useEffect(() => {
    if (!providers || !providers.allIds) {
      loadProgramProviders()
    }
  }, [])

  return (
    <div className="user-profile-container">
      {displayPreferencesPopup && (
        <NotificationsPopup
          handleClose={() => {
            setDisplayPreferencesPopup(false)
          }}
          disableSaveBtn={disabledSaveNotificationsBtn}
          handleSave={(data, recaptcha) => {
            setDisabledSaveNotificationsBtn(true)
            const dataToSend = {
              ...user,
              notifications: {
                timeZone: user.notifications.timeZone || programTimeZone,
                notifications: [
                  {
                    type: 'clinical_alerts',
                    ...getNotifyBy(data.alert),
                  },
                  {
                    type: 'daily_digest',
                    ...getNotifyBy(data.digest),
                  },
                ],
              },
            }

            if (
              dataToSend.role &&
              dataToSend.permissions &&
              typeof dataToSend.permissions.isSupervisor !== 'undefined'
            ) {
              delete dataToSend.role
            }

            delete dataToSend.permissions

            editSettings(
              dataToSend,
              () => {
                setDisabledSaveNotificationsBtn(false)
                setDisplayPreferencesPopup(false)
              },
              null,
              recaptcha,
            )
          }}
          notifications={notifications}
        />
      )}
      {displayEditPopup && (
        <EditUserProfilePopup
          profile={{
            ...profile,
            timeZone:
              (user.notifications && user.notifications.timeZone) ||
              programTimeZone,
          }}
          invalid={invalidForm}
          disableSaveBtn={disabledSaveProfileBtn}
          handleSave={(data, recaptcha) => {
            setDisabledSaveProfileBtn(true)
            setInvalidForm()
            const target = {
              ...user,
              notifications: {
                ...user.notifications,
                timeZone: data.timeZone,
              },
              profile: {
                ...data,
                timeZone: undefined,
              },
            }

            if (
              target.role &&
              target.permissions &&
              typeof target.permissions.isSupervisor !== 'undefined'
            ) {
              delete target.role
            }

            delete target.permissions

            editSettings(
              target,
              () => {
                setDisplayEditPopup(false)
              },
              (errorKey) => {
                setDisabledSaveProfileBtn(false)

                if (errorKey) {
                  setInvalidForm({ [errorKey]: true })
                }
              },
              recaptcha,
            )
          }}
          handleClose={() => {
            setDisabledSaveProfileBtn(false)
            setDisplayEditPopup(false)
          }}
        />
      )}
      {displayEditDatePopup && (
        <EditDatesPopup
          outOfOfficeData={
            user && user.notifications && user.notifications.outOfOffice
          }
          user={user}
          handleClose={() => {
            setDisplayEditDatePopup(false)
          }}
        />
      )}
      <header className="update-picture-line">
        <ProfilePicture treatment={{ profile }} id="patient-picture" />
        <h3 id="patient-name">{getProfileName(profile)}</h3>
      </header>
      <div className="user-profile-content">
        <div className="settings-part">
          <b className="xxxbig-font">{dStrings.scheduling}</b>
          <>
            <div>
              <b>{dStrings.oooDates}</b>
            </div>
            <div style={{ marginBottom: '15px' }}>
              {dStrings.letYourPatientKnow}
            </div>
            <div style={{ width: 'fit-content' }}>
              <OutOfOffice provider={user?.profile} user={user} />
            </div>
          </>
        </div>
        <div className="settings-part">
          <b className="xxxbig-font">{dStrings.offlineNotifications}</b>
          <NotificationsTable editMode={false} data={notifications} />
          <Button
            style={{ marginTop: '15px' }}
            text={dStrings.editPreferences}
            type="secondary"
            onClick={() => {
              setDisplayPreferencesPopup(true)
            }}
          />
        </div>
      </div>
    </div>
  )
}
UserProfile.propTypes = {
  user: PropTypes.object,
  timeZone: PropTypes.string,
  notifications: PropTypes.object,
}

const mapDispatchToProps = {
  editSettings,
  loadProgramProviders,
  editOOO,
}

function mapStateToProps(state) {
  let notifications
  let timeZone

  if (state.global.user && state.global.user.notifications) {
    timeZone = state.global.user.notifications.timeZone
    const temp = {}
    state.global.user.notifications.notifications.forEach((n) => {
      temp[n.type] = n
    })

    notifications = {
      alert: {
        email:
          temp.clinical_alerts.active &&
          [notifyByEnum.ALL, notifyByEnum.EMAIL].indexOf(
            temp.clinical_alerts.notifyBy,
          ) !== -1,
        text:
          temp.clinical_alerts.active &&
          [notifyByEnum.ALL, notifyByEnum.SMS].indexOf(
            temp.clinical_alerts.notifyBy,
          ) !== -1,
      },
      digest: {
        email:
          temp.daily_digest.active &&
          [notifyByEnum.ALL, notifyByEnum.EMAIL].indexOf(
            temp.daily_digest.notifyBy,
          ) !== -1,
        text:
          temp.daily_digest.active &&
          [notifyByEnum.ALL, notifyByEnum.SMS].indexOf(
            temp.daily_digest.notifyBy,
          ) !== -1,
      },
    }
  }

  return {
    user: state.global.user,
    timeZone,
    notifications,
    programTimeZone: state.global.configurations
      ? state.global.configurations.timeZone
      : defaultTimeZone,
    providers: state.program.careTeam && state.program.careTeam,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
