import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { AUTH_REDUCER_KEY, RootStateWithAuth } from '@valerahealth/redux-auth'
import { Overwrite } from 'utility-types'
import { TimeSlotSearchDocument, api as endpoints } from './generated'
import * as Types from '../../shared/generated.types'
import {
  AggregationsAggregationContainer,
  QueryDslBoolQuery,
  SearchSortContainer,
} from '../../opensearch.types'
import { handleGQLErrors } from '../../utils'
import { ESAggregationTemplate } from '../../shared/types'

export type TimeSlotSearchAggregationType = {
  [s: string]: ESAggregationTemplate<{ key: string; doc_count: number }>
}
export type EnhancedTimeSlotSearch = {
  timeSlotSearch: Omit<Types.TimeSlotSearchResFrag, 'aggregations'> & {
    aggregations?: TimeSlotSearchAggregationType
  }
}
const baseApi = createApi({
  reducerPath: 'availability',
  tagTypes: ['availability'],
  baseQuery: graphqlRequestBaseQuery({
    url: `https://${process.env.AVAILABILITY_API_DOMAIN}/graphql`,
    prepareHeaders: (headers, api) => {
      const state = api.getState() as RootStateWithAuth
      const { isAuthenticated, session } = state[AUTH_REDUCER_KEY]
      if (isAuthenticated) {
        headers.set('Authorization', `Bearer ${session?.accessToken.jwt}`)
      }
      return headers
    },
    customErrors: handleGQLErrors,
  }),
  endpoints,
}).injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    timeSlotSearch: build.query<
      EnhancedTimeSlotSearch,
      Overwrite<
        Types.TimeSlotSearchVariables,
        {
          query: QueryDslBoolQuery
          sort?: SearchSortContainer[]
          aggregations?: Record<string, AggregationsAggregationContainer>
        }
      >
    >({
      keepUnusedDataFor: 0, // want this data to be as fresh as possible
      providesTags: (res) => {
        const results = res?.timeSlotSearch?.results
        return results
          ? results.map((r) => ({ type: 'availability', id: r.id }))
          : []
      },
      query: ({ query, sort, aggregations, ...rest }) => ({
        document: TimeSlotSearchDocument,
        variables: {
          query: JSON.stringify(query),
          sort: sort && JSON.stringify(sort),
          aggregations: aggregations && JSON.stringify(aggregations),
          ...rest,
        },
      }),
      transformResponse: (res: Types.TimeSlotSearch) => ({
        timeSlotSearch: {
          ...res.timeSlotSearch,
          aggregations: res.timeSlotSearch.aggregations
            ? (JSON.parse(
                res.timeSlotSearch.aggregations,
              ) as TimeSlotSearchAggregationType)
            : undefined,
        },
      }),
    }),
    timeSlotAggregates: build.query<
      TimeSlotSearchAggregationType | null,
      Record<string, AggregationsAggregationContainer>
    >({
      keepUnusedDataFor: 3600, // 5 minutes
      query: (aggregations) => ({
        document: TimeSlotSearchDocument,
        variables: {
          query: '{}',
          aggregations: JSON.stringify(aggregations),
          pageSize: 0,
        },
      }),
      transformResponse: (res: Types.TimeSlotSearch) =>
        res.timeSlotSearch.aggregations
          ? (JSON.parse(
              res.timeSlotSearch.aggregations,
            ) as TimeSlotSearchAggregationType)
          : null,
    }),
  }),
})

export const availabilityApi = baseApi
