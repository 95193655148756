import {
  GetInsuranceForVerification,
  GetActiveInsurancePlans,
  GetAllInsurancePlans,
  GetInsurancePlans,
  InsurancePlanStatus,
  GetPayers,
  PayerFragment,
  PayerEnrollmentFragment,
  GetAllInsuranceMinimal,
  AllInsurancePlansMinimalFragment,
  GetPmAggregates,
  GetLocations,
  GetProvidersMinimal,
} from '../../shared/generated.types'
import { sortByStateCode } from '../../utils'
import { BaseApiType } from './baseApi'
import { GetAllInsuranceMinimalDocument } from './generated'

export default function enhanceEndpoints(api: BaseApiType) {
  return api
    .enhanceEndpoints({
      endpoints: {
        getProviderByCareManagerId: {
          providesTags: (res) => {
            const id = res?.getProviderByCareManagerId?._id
            return id ? [{ type: 'provider', id }] : []
          },
        },
        getProviders: {
          providesTags: (res) =>
            res?.getProviders?.map((p) => ({ type: 'provider', id: p._id })) ||
            [],
        },
        getProvidersMinimal: {
          providesTags: (res) =>
            res?.getProviders?.map((p) => ({ type: 'provider', id: p._id })) ||
            [],
          transformResponse: ({ getProviders }: GetProvidersMinimal) => ({
            getProviders: getProviders.sort((a, b) =>
              (a.display?.expandedName || '').localeCompare(
                b.display?.expandedName || '',
              ),
            ),
          }),
        },
        getProvider: {
          providesTags: (res) => {
            const id = res?.getProvider?._id
            return id ? [{ type: 'provider', id }] : []
          },
        },
        updateProvider: {
          invalidatesTags: (res) =>
            res?.updateProvider._id
              ? [{ type: 'provider', id: res.updateProvider._id }]
              : [],
          // DEFECT IN BACKEND DOES NOT RETURN ALL THE DATA ON UPDATE PROVIDER
          // async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
          //   const provider = await queryFulfilled
          //     .catch(() => ({
          //       data: { updateProvider: null },
          //     }))
          //     .then((r) => r.data.updateProvider)
          //   if (provider) {
          //     api.util
          //       .selectInvalidatedBy(getState() as any, [
          //         { type: 'provider', id: provider._id },
          //       ])
          //       .forEach(({ endpointName, originalArgs }) => {
          //         switch (endpointName) {
          //           case 'getProviderByCareManagerId':
          //             dispatch(
          //               api.util.updateQueryData(
          //                 endpointName,
          //                 originalArgs,
          //                 (draft) => {
          //                   draft.getProviderByCareManagerId = provider
          //                 },
          //               ),
          //             )
          //             break
          //           case 'getProvider':
          //             dispatch(
          //               api.util.updateQueryData(
          //                 endpointName,
          //                 originalArgs,
          //                 (draft) => {
          //                   draft.getProvider = provider
          //                 },
          //               ),
          //             )
          //             break
          //           default:
          //         }
          //       })
          //   }
          // },
        },
        getInsurancePlans: {
          providesTags: (res) => [
            'allInsurancePlans',
            ...(res?.getInsurancePlans?.map((p) => ({
              id: p._id,
              type: 'insurancePlan' as const,
            })) || []),
          ],
          transformResponse: (res: GetInsurancePlans) => ({
            getInsurancePlans: sortByStateCode(res.getInsurancePlans),
          }),
        },
        getAllInsurancePlans: {
          providesTags: (res) => [
            'allInsurancePlans',
            'allOutOfNetworkInsurancePlans',
            ...(res?.getInsurancePlans?.map((p) => ({
              id: p._id,
              type: 'insurancePlan' as const,
            })) || []),
            ...(res?.getOutOfNetworkInsurancePlans?.map((p) => ({
              id: p._id,
              type: 'insurancePlan' as const,
            })) || []),
          ],
          transformResponse: (res: GetAllInsurancePlans) => ({
            getOutOfNetworkInsurancePlans: sortByStateCode(
              res.getOutOfNetworkInsurancePlans,
            ),
            getInsurancePlans: sortByStateCode(res.getInsurancePlans),
          }),
        },
        getInsuranceForVerification: {
          providesTags: (res) => [
            'allInsurancePlans',
            'allOutOfNetworkInsurancePlans',
            ...(res?.getInsurancePlans?.map((p) => ({
              id: p._id,
              type: 'insurancePlan' as const,
            })) || []),
            ...(res?.getOutOfNetworkInsurancePlans?.map((p) => ({
              id: p._id,
              type: 'insurancePlan' as const,
            })) || []),
          ],
          transformResponse: (res: GetInsuranceForVerification) => ({
            getOutOfNetworkInsurancePlans: sortByStateCode(
              res.getOutOfNetworkInsurancePlans,
            ),
            getInsurancePlans: sortByStateCode(res.getInsurancePlans),
          }),
        },
        getActiveInsurancePlans: {
          keepUnusedDataFor: 86400, // one day
          providesTags: (res) => [
            'activeInsurancePlans',
            ...(res?.getInsurancePlans?.map((p) => ({
              type: 'insurancePlan' as const,
              id: p._id,
            })) || []),
          ],
          transformResponse: (res: GetActiveInsurancePlans) => ({
            getInsurancePlans: sortByStateCode(res.getInsurancePlans),
          }),
        },
        getLocations: {
          providesTags: (res) => [
            'location',
            ...(res?.getLocations?.map((p) => ({
              type: 'location' as const,
              id: p.id,
            })) || []),
          ],
          transformResponse: ({ getLocations }: GetLocations) => ({
            getLocations: getLocations.sort((a, b) =>
              a.name.localeCompare(b.name),
            ),
          }),
        },
        getPMAggregates: {
          keepUnusedDataFor: 6000,
          providesTags: (res) => [
            ...(res?.getInsurancePlans?.map((p) => ({
              type: 'insurancePlan' as const,
              id: p._id,
            })) || []),
            ...(res?.getLocations?.map((p) => ({
              type: 'location' as const,
              id: p.id,
            })) || []),
          ],
          transformResponse: (res: GetPmAggregates) => {
            const { getInsurancePlans, getLocations } = res
            return {
              getInsurancePlans: sortByStateCode(getInsurancePlans),
              getLocations: getLocations.sort((a, b) =>
                a.name.localeCompare(b.name),
              ),
            }
          },
        },
        appendInsurancePlanParticipants: {
          invalidatesTags: (res, error, { providerId }) =>
            res?.appendInsurancePlanParticipants
              ? [{ type: 'provider', id: providerId }]
              : [],
        },
        removeInsurancePlanParticipants: {
          invalidatesTags: (res, error, { providerId }) =>
            res?.removeInsurancePlanParticipants
              ? [{ type: 'provider', id: providerId }]
              : [],
        },
        updateInsurancePlanParticipant: {
          invalidatesTags: (res, error, { providerId }) =>
            res?.updateInsurancePlanParticipant
              ? [{ type: 'provider', id: providerId }]
              : [],
        },
        upsertInsurancePlanParticipants: {
          invalidatesTags: (res, error, req) =>
            req.content.map((v) => ({
              type: 'provider' as const,
              id: v.providerId,
            })),
        },
        createInsurancePlan: {
          invalidatesTags: (res) => {
            const tags: (
              | 'allInsurancePlans'
              | 'allOutOfNetworkInsurancePlans'
              | 'activeInsurancePlans'
            )[] = []

            if (res?.createInsurancePlan) {
              if (res.createInsurancePlan.isInNetwork) {
                tags.push('allInsurancePlans')
                if (
                  res.createInsurancePlan.status === InsurancePlanStatus.Active
                ) {
                  tags.push('activeInsurancePlans')
                }
              } else {
                tags.push('allOutOfNetworkInsurancePlans')
              }
            }
            return tags
          },
        },
        updateInsurancePlan: {
          invalidatesTags: (res, error, { id }) =>
            res?.updateInsurancePlan ? [{ type: 'insurancePlan', id }] : [],
        },
        deleteInsurancePlan: {
          invalidatesTags: (res, error, { id }) =>
            res?.deleteInsurancePlan ? [{ type: 'insurancePlan', id }] : [],
        },
        appendProviderLicenses: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.appendProviderLicenses ? [{ type: 'provider', id }] : [],
        },
        updateProviderLicenses: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.updateProviderLicenses ? [{ type: 'provider', id }] : [],
        },
        removeProviderLicenses: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.removeProviderLicenses ? [{ type: 'provider', id }] : [],
        },
        getProviderLicenseRequests: {
          providesTags: (res, error, { providerId: id }) =>
            res?.getProvider ? [{ type: 'ProviderLicenseRequests', id }] : [],
        },
        addProviderLicenseRequests: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.addProviderLicenseRequests
              ? [{ type: 'ProviderLicenseRequests', id }]
              : [],
        },
        updateProviderLicenseRequests: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.updateProviderLicenseRequests
              ? [{ type: 'ProviderLicenseRequests', id }]
              : [],
        },
        removeProviderLicenseRequests: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.removeProviderLicenseRequests
              ? [{ type: 'ProviderLicenseRequests', id }]
              : [],
        },
        getProviderDocument: {
          providesTags: (res, error, { providerId: id }) =>
            res?.getProviderDocument ? [{ type: 'ProviderDocuments', id }] : [],
        },
        getProviderDocumentsByProviderId: {
          providesTags: (res, error, { providerId: id }) =>
            res?.getProviderDocumentsByProviderId
              ? [{ type: 'ProviderDocuments', id }]
              : [],
        },
        getVerifiedProviderDocument: {
          providesTags: (res, error, { providerId: id }) =>
            res?.getVerifiedProviderDocument
              ? [{ type: 'VerifiedProviderDocuments', id }]
              : [],
        },
        getVerifiedProviderDocumentsByProviderId: {
          providesTags: (res, error, { providerId: id }) =>
            res?.getVerifiedProviderDocumentsByProviderId
              ? [{ type: 'VerifiedProviderDocuments', id }]
              : [],
        },
        appendProviderDocuments: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.appendProviderDocuments
              ? [{ type: 'ProviderDocuments', id }]
              : [],
        },
        updateProviderDocuments: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.updateProviderDocuments
              ? [{ type: 'ProviderDocuments', id }]
              : [],
        },
        removeProviderDocuments: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.removeProviderDocuments
              ? [{ type: 'ProviderDocuments', id }]
              : [],
        },
        appendVerifiedProviderDocuments: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.appendVerifiedProviderDocuments
              ? [{ type: 'VerifiedProviderDocuments', id }]
              : [],
        },
        updateVerifiedProviderDocuments: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.updateVerifiedProviderDocuments
              ? [{ type: 'VerifiedProviderDocuments', id }]
              : [],
        },
        removeVerifiedProviderDocuments: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.removeVerifiedProviderDocuments
              ? [{ type: 'VerifiedProviderDocuments', id }]
              : [],
        },
        getPayerEnrollmentsByProviderId: {
          providesTags: (res, error, { providerId: id }) => [
            { type: 'PayerEnrollments', id },
          ],
        },
        createPayerEnrollments: {
          async onQueryStarted(
            payload,
            { dispatch, getState, queryFulfilled },
          ) {
            const enrollments = await queryFulfilled
              .catch(() => ({
                data: {
                  createPayerEnrollments: [] as PayerEnrollmentFragment[],
                },
              }))
              .then((r) => r.data.createPayerEnrollments)
            if (enrollments.length) {
              api.util
                .selectInvalidatedBy(getState() as any, [
                  { type: 'PayerEnrollments', id: payload.providerId },
                ])
                .forEach(({ endpointName, originalArgs }) => {
                  dispatch(
                    api.util.updateQueryData(
                      endpointName as 'getPayerEnrollmentsByProviderId',
                      originalArgs,
                      (draft) => {
                        draft.getPayerEnrollmentsByProviderId =
                          draft.getPayerEnrollmentsByProviderId.concat(
                            enrollments,
                          )
                      },
                    ),
                  )
                })
            }
          },
        },
        updatePayerEnrollments: {
          async onQueryStarted(
            payload,
            { dispatch, getState, queryFulfilled },
          ) {
            const enrollments = await queryFulfilled
              .catch(() => ({
                data: {
                  updatePayerEnrollments: [] as PayerEnrollmentFragment[],
                },
              }))
              .then((r) => r.data.updatePayerEnrollments)
            if (enrollments.length) {
              const byId = Object.fromEntries(enrollments.map((v) => [v.id, v]))
              api.util
                .selectInvalidatedBy(getState() as any, [
                  { type: 'PayerEnrollments', id: payload.providerId },
                ])
                .forEach(({ endpointName, originalArgs }) => {
                  dispatch(
                    api.util.updateQueryData(
                      endpointName as 'getPayerEnrollmentsByProviderId',
                      originalArgs,
                      (draft) => {
                        draft.getPayerEnrollmentsByProviderId =
                          draft.getPayerEnrollmentsByProviderId.map(
                            (v) => byId[v.id] || v,
                          )
                      },
                    ),
                  )
                })
            }
          },
        },
        deletePayerEnrollments: {
          async onQueryStarted(
            payload,
            { dispatch, getState, queryFulfilled },
          ) {
            const enrollments = await queryFulfilled
              .catch(() => ({
                data: {
                  deletePayerEnrollments: [] as PayerEnrollmentFragment[],
                },
              }))
              .then((r) => r.data.deletePayerEnrollments)
            if (enrollments.length) {
              const ids = enrollments.map((v) => v.id)
              api.util
                .selectInvalidatedBy(getState() as any, [
                  { type: 'PayerEnrollments', id: payload.providerId },
                ])
                .forEach(({ endpointName, originalArgs }) => {
                  dispatch(
                    api.util.updateQueryData(
                      endpointName as 'getPayerEnrollmentsByProviderId',
                      originalArgs,
                      (draft) => {
                        draft.getPayerEnrollmentsByProviderId =
                          draft.getPayerEnrollmentsByProviderId.filter(
                            (v) => !ids.includes(v.id),
                          )
                      },
                    ),
                  )
                })
            }
          },
        },
        getPayers: {
          keepUnusedDataFor: 86400, // one day
          providesTags: ['payers'],
          transformResponse: (res: GetPayers) => ({
            getPayers: sortByStateCode(res.getPayers),
          }),
        },
        getPayersSummary: {
          keepUnusedDataFor: 86400, // one day
          providesTags: ['payers'],
          transformResponse: (res: GetPayers) => ({
            getPayers: sortByStateCode(res.getPayers),
          }),
        },
        createPayers: {
          async onQueryStarted(
            payload,
            { dispatch, getState, queryFulfilled },
          ) {
            const payers = await queryFulfilled
              .catch(() => ({
                data: { createPayers: [] as PayerFragment[] },
              }))
              .then((r) => r.data.createPayers)
            if (payers.length) {
              api.util
                .selectInvalidatedBy(getState() as any, ['payers'])
                .forEach(({ endpointName, originalArgs }) => {
                  dispatch(
                    api.util.updateQueryData(
                      endpointName as 'getPayers',
                      originalArgs,
                      (draft) => {
                        draft.getPayers = sortByStateCode(
                          draft.getPayers.concat(payers),
                        )
                      },
                    ),
                  )
                })
            }
          },
        },
        updatePayers: {
          async onQueryStarted(
            payload,
            { dispatch, getState, queryFulfilled },
          ) {
            const payers = await queryFulfilled
              .catch(() => ({
                data: { updatePayers: [] as PayerFragment[] },
              }))
              .then((r) => r.data.updatePayers)
            if (payers.length) {
              const byId = Object.fromEntries(payers.map((v) => [v.id, v]))
              api.util
                .selectInvalidatedBy(getState() as any, ['payers'])
                .forEach(({ endpointName, originalArgs }) => {
                  dispatch(
                    api.util.updateQueryData(
                      endpointName as 'getPayers',
                      originalArgs,
                      (draft) => {
                        draft.getPayers = sortByStateCode(
                          draft.getPayers.map((v) => byId[v.id] || v),
                        )
                      },
                    ),
                  )
                })
            }
          },
        },
        deletePayers: {
          async onQueryStarted(
            payload,
            { dispatch, getState, queryFulfilled },
          ) {
            const payers = await queryFulfilled
              .catch(() => ({
                data: { deletePayers: [] as PayerFragment[] },
              }))
              .then((r) => r.data.deletePayers)
            if (payers.length) {
              const ids = payers.map((v) => v.id)
              api.util
                .selectInvalidatedBy(getState() as any, ['payers'])
                .forEach(({ endpointName, originalArgs }) => {
                  dispatch(
                    api.util.updateQueryData(
                      endpointName as 'getPayers',
                      originalArgs,
                      (draft) => {
                        draft.getPayers = draft.getPayers.filter(
                          (v) => !ids.includes(v.id),
                        )
                      },
                    ),
                  )
                })
            }
          },
        },
        getProfessionalHistory: {
          providesTags: (res, error, { providerId: id }) => [
            { type: 'professionalHistories', id },
          ],
        },
        getProfessionalHistoryById: {
          providesTags: (res, error, { id }) => [
            { type: 'professionCategory', id },
          ],
        },
        createProfessionalHistory: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.createProfessionalHistory
              ? [{ type: 'professionalHistories', id }]
              : [],
        },
        updateProfessionalHistory: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.updateProfessionalHistory
              ? [{ type: 'professionalHistories', id }]
              : [],
        },
        deleteProfessionalHistory: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.deleteProfessionalHistory
              ? [{ type: 'professionalHistories', id }]
              : [],
        },
        upsertIdentifiers: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.upsertIdentifiers ? [{ type: 'provider', id }] : [],
        },
        deleteIdentifiers: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.deleteIdentifiers ? [{ type: 'provider', id }] : [],
        },
        getProviderCredentialing: {
          providesTags: (res, error, { providerId: id }) => [
            { type: 'providerCredential', id },
          ],
        },
        updateProviderCredentialing: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.updateProviderCredentialing
              ? [{ type: 'providerCredential', id }]
              : [],
        },
        getProviderExternalAccounts: {
          providesTags: (res, error, { providerId: id }) => [
            { type: 'providerExternalAccount', id },
          ],
        },
        upsertProviderExternalAccounts: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.upsertProviderExternalAccounts
              ? [{ type: 'providerExternalAccount', id }]
              : [],
        },
        deleteProviderExternalAccounts: {
          invalidatesTags: (res, error, { providerId: id }) =>
            res?.deleteProviderExternalAccounts
              ? [{ type: 'providerExternalAccount', id }]
              : [],
        },
        bulkUpdateInsurancePlans: {
          invalidatesTags: (res) =>
            res?.bulkUpdateInsurancePlans
              ? res?.bulkUpdateInsurancePlans.map((plan) => ({
                  type: 'insurancePlan',
                  id: plan._id,
                }))
              : ['allInsurancePlans', 'allOutOfNetworkInsurancePlans'],
        },
        getPracticeStates: {
          keepUnusedDataFor: 86400,
        },
      },
    })
    .injectEndpoints({
      overrideExisting: true,
      endpoints: (build) => ({
        getAllInsuranceMinimal: build.query<
          AllInsurancePlansMinimalFragment[],
          void
        >({
          query: (variables) => ({
            document: GetAllInsuranceMinimalDocument,
            variables,
          }),
          providesTags: (res) => [
            'allInsurancePlans',
            ...(res?.map((p) => ({
              id: p._id,
              type: 'insurancePlan' as const,
            })) || []),
          ],
          transformResponse: (
            res: GetAllInsuranceMinimal,
          ): AllInsurancePlansMinimalFragment[] =>
            sortByStateCode([
              ...res.getInsurancePlans,
              ...res.getOutOfNetworkInsurancePlans,
            ]),
        }),
      }),
    })
}
