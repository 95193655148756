import { useMemo } from 'react'
import { PatientStatus } from '@valerahealth/rtk-query'
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Stack,
  Typography,
  DrChronoIcon,
  NexGenIcon,
} from '@valerahealth/ui-components'
import { StartPatientVideoSession } from '@valerahealth/ui-components/features'
import { format, formatIsoDate } from '@valerahealth/ui-components/utils/date'
import {
  Phone as PhoneIcon,
  Email as EmailIcon,
} from '@valerahealth/ui-components/icons'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@valerahealth/ui-translation'
import { isWellnessSelector } from 'redux/selectors'
import { useReduxSelector } from 'redux/store'
import { useTreatmentActionsMenu } from 'components/common/CMTreatmentActionsMenu'
import { useFeatureFlagNoStartSession } from '@valerahealth/ui-components/utils/hooks/useFeatureFlagNoStartSession'
import {
  TreatmentRoomRouteContext,
  getProfileName,
} from '../utilities/treatments'
import { dateFormatEnum, genderEnum2 } from '../utilities/enums'
import CameraOnIcon from '../../images/camera_on.svg'

const ProfileStatus = ({
  status,
  reason,
}: {
  status: PatientStatus
  reason?: string | null
}) => {
  const { t } = useTranslation()

  const { reasonLabel, color } = useMemo(() => {
    let reasonLabel
    let color: 'error' | 'warning' | 'info' = 'error'

    if (status === PatientStatus.Waitlist) {
      color = 'info'
    } else if (
      status === PatientStatus.Pending ||
      status === PatientStatus.AwaitingInitial ||
      status === PatientStatus.Lead
    ) {
      color = 'warning'
    } else if (status === PatientStatus.ClosedDischarged) {
      if (reason === `Don't Readmit`)
        reasonLabel = t(`PatientStatus.dischargedDoNotReadmit`)

      if (reason === `Never in Service`) {
        reasonLabel = t(`PatientStatus.dischargedNeverInService`)
        color = 'warning'
      }
    }

    return {
      reasonLabel,
      color,
    }
  }, [reason, status, t])

  return (
    <Chip
      size="small"
      color={color}
      label={`${t(status)}${reasonLabel ? `: ${reasonLabel}` : ''}`}
    />
  )
}

const TreatmentHeader = ({
  treatment,
  treatmentProfile,
  patient,
  nextAppt,
  lastAppt,
}: Partial<TreatmentRoomRouteContext>) => {
  const treatmentId = useParams().treatmentId!
  const isWellness = useReduxSelector(isWellnessSelector)
  const { t } = useTranslation()
  const { TreatmentActionsMenuPopups, TreatmentActionsMenu } =
    useTreatmentActionsMenu()
  const hideStartSessionButton = useFeatureFlagNoStartSession()
  const { integration: treatmentEmrStatus } = patient || {}
  const { nextgenId } = treatmentEmrStatus || {}

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          zIndex: 1,
          width: '100%',
          borderRadius: 0,
          p: 2,
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar src={treatment?.profile?.picture || undefined} />
          <div>
            <Typography variant="subtitle2">
              {getProfileName(treatmentProfile || treatment?.profile)}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              display="flex"
              alignItems="center"
              gap={1}
            >
              {t('MRN')} {treatmentProfile?.mrn}{' '}
              {nextgenId && (
                <NexGenIcon
                  iconStyle={{ height: 12 }}
                  alt="NextGen"
                  title={`Patient Nextgen ID: ${nextgenId}`}
                />
              )}
              <DrChronoIcon {...treatmentEmrStatus} />
            </Typography>
          </div>
        </Stack>
        <Divider flexItem orientation="vertical" />
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Typography
              variant="caption"
              component="p"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <PhoneIcon fontSize="small" />
              {treatmentProfile?.primaryPhone}
            </Typography>
            <Typography
              variant="caption"
              component="p"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <EmailIcon fontSize="small" />
              {treatmentProfile?.primaryEmail || '-'}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Typography component="p" variant="caption">
              <Typography color="text.secondary" variant="caption">
                {t('DOB')}:&nbsp;{' '}
              </Typography>
              {treatmentProfile?.dateOfBirth
                ? formatIsoDate(
                    treatmentProfile?.dateOfBirth,
                    dateFormatEnum.SMALL_DATE_BASIC,
                  )
                : '-'}
            </Typography>
            <Typography component="p" variant="caption">
              <Typography color="text.secondary" variant="caption">
                {t('sex')}:&nbsp;
              </Typography>

              {(treatmentProfile?.gender &&
                genderEnum2[treatmentProfile.gender]) ||
                '-'}
            </Typography>
            <Typography component="p" variant="caption">
              <Typography color="text.secondary" variant="caption">
                {t('pronouns')}:&nbsp;
              </Typography>
              {treatmentProfile?.pronouns || '-'}
            </Typography>
          </Stack>
        </Stack>
        <Divider flexItem orientation="vertical" />
        <Box mr="auto">
          <Typography component="p" variant="caption">
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                marginRight: '5px',
              }}
            >
              {t('next')}:&nbsp;
            </Typography>
            {nextAppt
              ? `${format(nextAppt.start, dateFormatEnum.SMALL_DATE_BASIC)}`
              : '-'}
          </Typography>
          <Typography component="p" variant="caption">
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                marginRight: '5px',
              }}
            >
              {t('last')}:&nbsp;
            </Typography>
            {lastAppt
              ? `${format(lastAppt.start, dateFormatEnum.SMALL_DATE_BASIC)}`
              : '-'}
          </Typography>
        </Box>

        {treatmentProfile?.status &&
          treatmentProfile.status !== PatientStatus.Active && (
            <ProfileStatus
              status={treatmentProfile?.status}
              reason={treatment?.dischargeInfo?.reason?.trim()}
            />
          )}

        {treatment && treatmentProfile && (
          <>
            {!hideStartSessionButton && (
              <StartPatientVideoSession
                enablePatientStatusCheck={!!isWellness}
                patientId={treatment.patientId}
                treatmentId={treatment.id}
                patientStatus={treatmentProfile.status}
              >
                <Button
                  sx={{ ml: 'auto' }}
                  variant="contained"
                  color="secondary"
                  startIcon={<CameraOnIcon />}
                >
                  {t('startSession')}
                </Button>
              </StartPatientVideoSession>
            )}
            <TreatmentActionsMenu
              {...{
                treatmentId,
                firstName: treatmentProfile.firstName,
                lastName: treatmentProfile.lastName,
                mrn: treatmentProfile.mrn,
                emrId: treatmentEmrStatus?.emrId,
                lumaId: treatmentEmrStatus?.lumaId,
                nextgenId: treatmentEmrStatus?.nextgenId,
                isIntegrationActive: treatmentEmrStatus?.isActive,
                videoLink: treatmentProfile.videoLink,
                phone: treatmentProfile.primaryPhone ?? '',
                email: treatmentProfile.primaryEmail ?? '',
                status: treatmentProfile.status,
              }}
            />
          </>
        )}
      </Paper>
      <TreatmentActionsMenuPopups />
    </>
  )
}

export default TreatmentHeader
