import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  type Dispatch,
} from 'react'
import { actions, initialState as defaultState } from './reduxSlice'
import { CalendarStates } from './Calendar.type'

type Actions = typeof actions

export type CalendarActionPayloadTypes = ReturnType<Actions[keyof Actions]>

export type CalendarDispatchActions = {
  [Key in keyof Actions]: (...args: Parameters<Actions[Key]>) => void
}

const CalendarContext = createContext<{
  selectCalendarState: () => CalendarStates
  actions: CalendarDispatchActions
}>({
  selectCalendarState: () => defaultState,
  actions,
})
CalendarContext.displayName = 'CalendarContext'

const { Provider } = CalendarContext

export type CalendarContextProviderProps = {
  selectCalendarState: () => CalendarStates
  useDispatch: () => Dispatch<CalendarActionPayloadTypes>
}

export const CalendarContextProvider = ({
  children,
  selectCalendarState,
  useDispatch,
}: CalendarContextProviderProps & {
  children: ReactNode | ReactNode[]
}) => {
  const dispatch = useDispatch()

  const dispatchActions = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(actions).map(([k, v]) => [
          k,
          (...args: [any]) => dispatch(v(...(args as [never]))),
        ]),
      ) as CalendarDispatchActions,
    [dispatch],
  )

  const value = useMemo(
    () => ({ selectCalendarState, actions: dispatchActions }),
    [selectCalendarState, dispatchActions],
  )

  return <Provider value={value}>{children}</Provider>
}

export const useCalendarContext = () => useContext(CalendarContext)
