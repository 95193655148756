import * as types from './actionTypes'
import * as treatmentAPI from '../../api/treatmentAPI'
import * as providerAPI from '../../api/providerAPI'
import { callApi } from './utilities'
import { addUserMessage } from './globalActions'
import { loadPlans } from './treatmentRoom'
import dStrings from '../../strings.json'
import { pageSize } from '../../components/utilities/enums'
import {
  getBulkFilterGroup,
  getFilterOptions,
} from '../../components/utilities/treatments'

export function clearCaseload() {
  return { type: types.CLEAR_CASELOAD }
}

export function sortCaseload(key) {
  return { type: types.SORT_PENDING_LIST, key }
}
export function searchTextInTreatment(text) {
  return { type: types.TREATMENT_SEARCH, text }
}

export function changeShowMenuValue(showMenuKey) {
  return { type: types.CHANGE_SHOW_MENU_VALUE, showMenuKey }
}

export function toggleFilterMenu() {
  return { type: types.TOGGLE_FILTER_MENU }
}

export function toggleCaseloadState(value) {
  return { type: types.CHANGE_CASELOAD_STATE, value }
}

export function selectTreatmentRow(id) {
  return { type: types.TOGGLE_SELECT_TREATMENT_ROW, id }
}

export function selectAllTreatments() {
  return { type: types.SELECT_ALL_TREATMENTS }
}

export function unselectAllTreatments() {
  return { type: types.UNSELECT_ALL_TREATMENTS }
}

export function unselectAllSelectedTreatments() {
  return { type: types.UNSELECT_ALL_SELECTED_TREATMENTS }
}

export function resetGoToCaseload() {
  return { type: types.RESET_GO_TO_CASELOAD }
}

export function mediaLoadedSuccessfully(media) {
  return { type: types.LOAD_MEDIA_ITEM_SUCCESS, media }
}

export function loadCaseloadFilterNumbers(loadingMore) {
  return (dispatch, getState) => {
    return callApi({
      name: 'loadCaseloadFilterNumbers',
      dispatch,
      call: () => {
        return providerAPI
          .loadCaseloadFilterNumbers(getState().auth?.session?.user?.careManagerId)
          .catch(() => {})
      },
      success: (numbers) => {
        dispatch({
          type: types.SET_FILTER_TREATMENT,
          numbers,
          loadingMore,
        })
      },
      hideLoader: true,
    })
  }
}

export function loadPendingTreatments() {
  return (dispatch, getState) => {
    return callApi({
      name: 'loadPendingTreatments',
      dispatch,
      call: () => {
        return providerAPI
          .loadPendingTreatment(getState().auth?.session?.user?.careManagerId)
          .catch(() => {})
      },
      success: (treatments) => {
        dispatch({
          type: types.LOAD_PENDING_TREATMENTS_SUCCESS,
          treatments,
        })
      },
    })
  }
}

export function removePendingTreatment(id) {
  return (dispatch, getState) => {
    return callApi({
      name: 'removePendingTreatment',
      dispatch,
      call: () => {
        return providerAPI
          .removePendingTreatment(getState().auth?.session?.user?.careManagerId, id)
          .catch(() => {})
      },
      success: () => {
        dispatch({
          type: types.REMOVE_PENDING_USER,
          id,
        })
      },
      successMessage: dStrings.userDeleted,
      error: () => {},
    })
  }
}

export function loadTreatments({
  options,
  hideLoader,
  nextPage,
  pagination,
  search,
  callback,
}) {
  return (dispatch, getState) => {
    const caseload = JSON.parse(JSON.stringify(getState().caseload))

    const pagin = pagination ? { ...pagination } : caseload.pagination

    if (nextPage) {
      if (caseload.cannotLoadMore) {
        callback?.()
        return
      }
      pagin.pageNumber += 1
    } else {
      pagin.pageNumber = 1
    }

    const filterOptions = getFilterOptions(caseload, options, search)

    return callApi({
      name: 'loadTreatments',
      dispatch,
      call: () => {
        return providerAPI
          .getTreatments({
            id: getState().auth?.session?.user?.careManagerId,
            options: filterOptions,
            pagination: pagin,
            caseloadState: caseload.caseloadState,
          })
          .catch(() => {})
      },
      success: (response) => {
        const treatments = response.data
        const treatmentCount =
          response.header && response.header['x-total-items']
            ? parseInt(response.header['x-total-items'], 10)
            : undefined

        let cannotLoadMore = false
        if (treatments.length < pageSize.CASELOAD) {
          cannotLoadMore = true
        }

        dispatch({
          type: types.LOAD_TREATMENTS_SUCCESS,
          treatments,
          options: filterOptions,
          pagination: pagin,
          cannotLoadMore,
          treatmentCount,
        })

        callback?.()
      },
      hideLoader,
    })
  }
}

export const assignItems = (item, errorCb) => {
  return (dispatch, getState) => {
    return callApi({
      name: 'assignItems',
      dispatch,
      call: () => {
        const state = getState()
        const group = getBulkFilterGroup(state.caseload)

        const textItem = ~['reminder', 'message'].indexOf(item.type)
        return providerAPI.assignItems({
          id: state.auth.session?.user.careManagerId,
          group,
          message: textItem ? item.info.text : undefined,
          attachment: !textItem
            ? { target: item.type, templateId: item.info.templateId }
            : undefined,
        })
      },
      success: () => {
        dispatch(addUserMessage('success', dStrings.itemSent))
      },
      error: (err) => {
        errorCb?.(err)
      },
    })
  }
}

export const assignPlans = (treatmentIds, plansId, errorCb) => {
  return (dispatch, getState) => {
    const group = getBulkFilterGroup(getState().caseload)

    return callApi({
      name: 'assignPlans',
      dispatch,
      call: () => {
        if (typeof treatmentIds === 'object') {
          return providerAPI.assignPlans({
            id: getState().auth.session?.user.careManagerId,
            group,
            templateIds: plansId,
          })
        }
        return treatmentAPI.assignPlansToSingleTreatment(treatmentIds, plansId)
      },
      success: () => {
        if (typeof treatmentIds === 'object') dispatch(loadTreatments({}))
        // for caseload page
        else {
          dispatch(loadPlans(getState().treatmentRoom.selectedId)) // for treatment plans page
        }
        dispatch(
          addUserMessage(
            'success',
            plansId.length > 1 ? dStrings.plansAssigned : dStrings.planAssigned,
          ),
        )
      },
      error: (err) => {
        errorCb?.(err)
      },
    })
  }
}

export function loadNotifications({ nextPage, hideLoader, cb }) {
  return (dispatch, getState) => {
    const notif = getState().caseload.notifications
    const { pagination } = notif

    if (nextPage) {
      if (notif.cannotLoadMore) return

      pagination.pageNumber += 1
    }

    return callApi({
      name: 'loadNotifications',
      dispatch,
      hideLoader,
      call: () => {
        return providerAPI.getNotifications(
          getState().auth?.session?.user?.careManagerId,
          pagination,
        )
      },
      success: (notifications) => {
        let cannotLoadMore = false
        if (notifications && notifications.length < pageSize.NOTIFICATIONS)
          cannotLoadMore = true

        cb?.()

        dispatch({
          type: types.LOAD_NOTIFICATIONS_SUCCESS,
          notifications: notifications || [],
          pagination,
          cannotLoadMore,
          nextPage,
        })
      },
    })
  }
}

export function receiveNewNotification(data) {
  return (dispatch, getState) => {
    const state = getState()

    if (state.caseload?.byId?.[data.treatmentId]) {
      dispatch({ type: types.NOTIFICATION_RECEIVED, data })
      return
    }

    dispatch({
      type: types.LOADING_DATA_FOR_TREATMENT,
      treatmentId: data.treatmentId,
    })

    return callApi({
      name: 'receiveNewNotification',
      dispatch,
      call: () => {
        return providerAPI
          .getTreatments({
            id: getState().auth?.session?.user?.careManagerId,
            options: { treatments: { id: data.treatmentId } },
          })
          .catch(() => {})
      },
      success: (response) => {
        dispatch({
          type: types.STOP_LOADING_DATA_FOR_TREATMENT,
          treatmentId: data.treatmentId,
        })
        dispatch({
          type: types.NOTIFICATION_RECEIVED,
          data,
          treatment: response?.data[0],
        })
      },
      hideLoader: true,
    })
  }
}

export function setChannelSeen(treatmentId) {
  return (dispatch) => {
    return callApi({
      name: 'setChannelSeen',
      hideLoader: true,
      dispatch,
      call: () => {
        dispatch({
          type: types.SET_CHANNEL_SEEN,
          treatmentId,
        })
        return treatmentAPI.setChannelSeen(treatmentId)
      },
      success: () => {},
    })
  }
}

export function setAllMessageAsReaded() {
  return (dispatch, getState) => {
    const profile = getState().auth?.session?.user
    return dispatch({
      type: types.ALL_MESSAGES_READED,
      viewer: {
        id: profile?.careManagerId,
        type: profile?.role,
        ts: new Date().toISOString(),
      },
    })
  }
}

export function setMessageAsUnreaded(messageId) {
  return (dispatch, getState) => {
    const profile = getState().auth?.session?.user
    return dispatch({
      type: types.SET_MESSAGES_UNREADED,
      data: { userId: profile?.careManagerId, messageId },
    })
  }
}
