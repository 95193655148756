import { createApi } from '@reduxjs/toolkit/query/react'
import { type Overwrite } from 'utility-types'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import {
  QueryDslQueryContainer,
  type AggregateName,
  type AggregationsAggregate,
  type AggregationsAggregationContainer,
  QueryDslBoolQuery,
  SearchSortContainer,
} from '@opensearch-project/opensearch/api/types'
import {
  AUTH_REDUCER_KEY,
  Permission,
  RootStateWithAuth,
} from '@valerahealth/redux-auth'
import * as Types from '../../shared/generated.types'
import {
  GetAggregatesDocument,
  PatientTypeAheadDocument,
  SearchPatientByTreatmentIdDocument,
  SearchPatientsDocument,
  api,
} from './generated'

import {
  initApplyPermissionheaders,
  type EndpointPermissions,
  removeSpecialCharForStringQuery,
} from '../../utils'

const endpointPermissions: EndpointPermissions = {
  searchPatients: Permission.Patient_Read,
  getAggregates: Permission.Patient_Read,
}

const applyPermissionHeaders = initApplyPermissionheaders(endpointPermissions)

export const patientSearchApi = createApi({
  reducerPath: 'searchPatientApi',
  keepUnusedDataFor: 120,
  tagTypes: ['patient'],
  baseQuery: graphqlRequestBaseQuery({
    url: `https://${process.env.PATIENTSEARCH_API_DOMAIN}/graphql`,
    prepareHeaders: (headers, api) => {
      const state = api.getState() as RootStateWithAuth
      const { isAuthenticated, session } = state[AUTH_REDUCER_KEY]
      if (isAuthenticated) {
        headers.set('Authorization', `Bearer ${session?.accessToken.jwt}`)
        applyPermissionHeaders(headers, session!, api.endpoint)
      }
      return headers
    },
    customErrors: (error) => {
      console.error(error.response)
      const e = error.response.errors?.[0] as any
      const response: ErrorResponse = {
        name: e?.errorType || '',
        message: e?.message || '',
        stack: error.stack || '',
      }
      return response
    },
  }),
  endpoints: api,
}).injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    searchPatients: build.query<
      Types.SearchPatients,
      Overwrite<
        Types.SearchPatientsVariables,
        {
          query: QueryDslBoolQuery
          sort?: SearchSortContainer[]
        }
      >
    >({
      query: ({ query, sort, ...rest }) => ({
        document: SearchPatientsDocument,
        variables: {
          query: JSON.stringify(query),
          sort: sort && JSON.stringify(sort),
          ...rest,
        },
      }),
      providesTags: (res) =>
        res?.searchPatients.patients.map(
          (v) => ({ id: v.treatmentId, type: 'patient' } as const),
        ) || [],
    }),
    // Overwriting the generated query to take in a proper type as an argument and serialize, then unserialize the response on the way out
    patientTypeAhead: build.query<
      Types.PatientTypeAhead['searchPatients'],
      {
        queryText: string
        pageSize?: number
        patientStatusBlackList?: Types.PatientStatus[]
      }
    >({
      query: ({ queryText, pageSize = 10, patientStatusBlackList }) => {
        const query: QueryDslBoolQuery = {
          should: {
            //boost active patients
            term: {
              'status.keyword': Types.PatientStatus.Active,
            },
          },
          must: [
            {
              query_string: {
                fields: [
                  'mrn',
                  'name.fullName^2',
                  'primaryEmail',
                  'primaryPhone',
                ],
                query: removeSpecialCharForStringQuery(queryText || '')
                  .split(' ')
                  .map((s) => `*${s}*`)
                  .join(' '),
                fuzziness: 'AUTO',
                default_operator: 'AND',
              },
            },
            ...(!patientStatusBlackList
              ? []
              : [
                  {
                    bool: {
                      must_not: {
                        terms: {
                          'status.keyword': patientStatusBlackList,
                        },
                      },
                    },
                  },
                ]),
          ],
        }

        const sort: SearchSortContainer[] = [
          {
            _score: { order: 'desc' },
          },
        ]

        return {
          document: PatientTypeAheadDocument,
          variables: {
            query: JSON.stringify(query),
            sort: JSON.stringify(sort),
            from: 0,
            pageSize,
          },
        }
      },
      transformResponse: (result: Types.PatientTypeAhead) => {
        return result.searchPatients
      },
      providesTags: (res) =>
        res?.patients.map(
          (v) => ({ id: v.treatmentId, type: 'patient' } as const),
        ) || [],
    }),
    // Overwriting the generated query to take in a proper type as an argument and serialize, then unserialize the response on the way out
    searchPatientByTreatmentId: build.query<
      Types.MinimalSearchablePatientFrag | null,
      { treatmentId: string }
    >({
      query: ({ treatmentId }) => {
        return {
          document: SearchPatientByTreatmentIdDocument,
          variables: {
            query: JSON.stringify({
              must: {
                term: {
                  'treatmentId.keyword': treatmentId,
                },
              },
            } as QueryDslQueryContainer),
          },
        }
      },
      transformResponse: (result: Types.SearchPatientByTreatmentId) => {
        return result.searchPatients.patients[0] || null
      },
      providesTags: (res, err, req) => [
        { type: 'patient', id: req.treatmentId },
      ],
    }),
    getAggregates: build.query<
      Record<AggregateName, AggregationsAggregate>,
      Record<string, AggregationsAggregationContainer>
    >({
      query: (aggregations) => ({
        document: GetAggregatesDocument,
        variables: {
          aggregations: JSON.stringify(aggregations),
        },
      }),
      transformResponse: (
        result: Types.GetAggregates,
      ): Record<AggregateName, AggregationsAggregate> => {
        const agg = result.searchPatients.aggregations
        let response: Record<AggregateName, AggregationsAggregate> = {}
        try {
          if (agg) response = JSON.parse(agg) as typeof response
        } catch (e) {
          console.error(e)
        }
        return response
      },
    }),
  }),
})

export type PatientSearchApiType = typeof patientSearchApi
