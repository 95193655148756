import { useEffect } from 'react'
import { taskApi } from '@valerahealth/rtk-query'
import {
  Route,
  Routes,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Mode } from '../constants'
import { ReadWriteTaskForm } from '../TaskForm/TaskForm'
import { BulkUpdateForm } from './BulkUpdateForm'
import {
  IconButton,
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  LinearProgress,
} from '../../../base'
import { useTranslation } from '../../../utils'
import { CloseOutlined } from '../../../icons'
import { useNotify } from '../../Notifications'

const TaskContent = ({ mode = Mode.WRITE }: { mode?: Mode }) => {
  const { _id } = useParams() as { _id: string }
  const navigate = useNavigate()
  const notify = useNotify()
  const { t } = useTranslation()

  const res = taskApi.useGetTaskQuery({ _id })
  const task = res.data
  const patient = task ? task.patient : undefined

  useEffect(() => {
    if (res.error) {
      notify({
        severity: 'error',
        message: res?.error?.message || t('serverError.500'),
      })
      navigate(`/tasks${window.location.search || ''}`)
    }
  }, [res.error, navigate, notify, t])

  return !task ? (
    <Box height="400px">
      <LinearProgress />
    </Box>
  ) : (
    <ReadWriteTaskForm
      mode={mode}
      onEdit={
        mode === Mode.READ
          ? () => {
              navigate(`/tasks/edit/${_id}${window.location.search || ''}`)
            }
          : undefined
      }
      onCancel={() =>
        navigate(`/tasks/view/${_id}${window.location.search || ''}`)
      }
      onSaveSuccess={() =>
        navigate(`/tasks/view/${_id}${window.location.search || ''}`)
      }
      onDeleteSuccess={() => {
        navigate(`/tasks${window.location.search || ''}`)
      }}
      task={task}
      patient={{
        treatmentId: task?.treatmentId || '',
        patientId: patient?.id || '',
        display: patient?.display,
        mrn: patient?.mrn,
        lumaId: patient?.lumaId,
        nextgenId: patient?.nextgenId,
      }}
      patientReadOnly
    />
  )
}

export function TaskFormDialog() {
  const openFormModal = !!useMatch('tasks/:formPath/*')
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Dialog open={!!openFormModal} fullWidth maxWidth="sm">
      <Routes>
        <Route
          path="/edit/:_id"
          element={
            <>
              <DialogTitle
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {t('Edit Task')}
                <IconButton
                  title={t('close')}
                  onClick={() =>
                    navigate(`/tasks${window.location.search || ''}`)
                  }
                >
                  <CloseOutlined />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TaskContent />
              </DialogContent>
            </>
          }
        />
        <Route
          path="/view/:_id"
          element={
            <>
              <DialogTitle
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {t('Task')}
                <IconButton
                  title={t('close')}
                  onClick={() =>
                    navigate(`/tasks${window.location.search || ''}`)
                  }
                >
                  <CloseOutlined />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TaskContent mode={Mode.READ} />
              </DialogContent>
            </>
          }
        />
        <Route
          path="/add"
          element={
            <>
              <DialogTitle
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {t('Add Task')}
                <IconButton
                  title={t('close')}
                  onClick={() =>
                    navigate(`/tasks${window.location.search || ''}`)
                  }
                >
                  <CloseOutlined />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <ReadWriteTaskForm
                  mode={Mode.WRITE}
                  onCancel={() =>
                    navigate(`/tasks${window.location.search || ''}`)
                  }
                  onSaveSuccess={(task) =>
                    navigate(
                      `/tasks/view/${task._id}${window.location.search || ''}`,
                    )
                  }
                />
              </DialogContent>
            </>
          }
        />
        <Route
          path="/bulkupdate/:ids"
          element={
            <>
              <DialogTitle
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  pb: '0px',
                }}
              >
                {t('Bulk Update')}
                <IconButton
                  title={t('close')}
                  onClick={() =>
                    navigate(`/tasks${window.location.search || ''}`)
                  }
                >
                  <CloseOutlined />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <BulkUpdateForm
                  onCancel={() =>
                    navigate(`/tasks${window.location.search || ''}`)
                  }
                  onSaveSuccess={() =>
                    navigate(`/tasks${window.location.search || ''}`)
                  }
                />
              </DialogContent>
            </>
          }
        />
      </Routes>
    </Dialog>
  )
}
