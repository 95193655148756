import {
  Availability,
  ClinicalAges,
  CommunityIdentity,
  DayOfWeek,
  DayPart,
  EmploymentStatus,
  InsurancePlanLineOfBusiness,
  Ethnicity,
  GenderIdentity,
  LegalSex,
  PayerEnrollmentStatus,
  ProviderServiceType,
  Race,
  STATES,
  ProviderIdentifierType,
} from '@valerahealth/rtk-query'
import LABELS from '@valerahealth/ui-translation/locales/en'
import { sortOnProperty } from '../../utils'
import { generateTimeOptions } from '../../utils/date'
// write fns the memoize the options the first time they are used

export type IValueOption<T = string> = { value: T; label: string }
export type IValueOptions<T = string> = IValueOption<T>[]

let _stateOptions: IValueOptions
export const getUSStateValueOptions = () => {
  _stateOptions =
    _stateOptions ||
    Object.values(STATES).map((v) => ({
      value: v.code,
      label: `${v.name} (${v.code})`,
    }))
  return _stateOptions
}

let _providerEmploymentStatisOptions: IValueOptions<EmploymentStatus>
export const getProviderEmploymentStatisOptions = () => {
  _providerEmploymentStatisOptions =
    _providerEmploymentStatisOptions ||
    Object.values(EmploymentStatus).map((value) => ({
      value,
      label: LABELS.EmploymentStatus[value],
    }))
  return _providerEmploymentStatisOptions
}

let _payerEnrollmentStatusOptions: IValueOptions<PayerEnrollmentStatus>
export const getPayerEnrollmentStatusOptions = () => {
  _payerEnrollmentStatusOptions =
    _payerEnrollmentStatusOptions ||
    sortOnProperty(
      Object.values(PayerEnrollmentStatus).map((v) => {
        return {
          value: v,
          label: LABELS.PayerEnrollmentStatus[v],
        }
      }),
      'label',
    )
  return _payerEnrollmentStatusOptions
}

export const availabilityRank = {
  [Availability.OpenPrioritized]: 10,
  [Availability.OpenFlexible]: 9,
  [Availability.Open]: 8,
  [Availability.CareCoordinatorOnly]: 7,
  [Availability.WaitlistOnly]: 6,
  [Availability.PilotOnly]: 5,
  [Availability.Pending]: 4,
  [Availability.Recruiting]: 3,
  [Availability.Closed]: 2,
}

export const availabilityRankMap = new Map(Object.entries(availabilityRank))

let _availabilityOptions: IValueOptions<Availability>
export const getAvailabilityOptions = () => {
  _availabilityOptions =
    _availabilityOptions ||
    [
      Availability.OpenPrioritized,
      Availability.OpenFlexible,
      Availability.Open,
      Availability.CareCoordinatorOnly,
      Availability.WaitlistOnly,
      Availability.Pending,
      Availability.Recruiting,
      Availability.Closed,
    ].map((s) => ({
      value: s,
      label: LABELS.Availability[s],
    }))
  return _availabilityOptions
}

let _lineOfBusinessOptions: IValueOptions<InsurancePlanLineOfBusiness>[]
export const getLineOfBusinessOptions = () => {
  _lineOfBusinessOptions =
    _lineOfBusinessOptions ||
    sortOnProperty(
      Object.values(InsurancePlanLineOfBusiness).map((v) => {
        return {
          value: v,
          label: LABELS.InsurancePlanLineOfBusiness[v],
        }
      }),
      'label',
    )
  return _lineOfBusinessOptions
}

let _clinicalAgeOptions: IValueOptions<ClinicalAges>
export const getClinicalAgesOptions = () => {
  _clinicalAgeOptions =
    _clinicalAgeOptions ||
    Object.values(ClinicalAges).map((value) => ({
      value,
      label: LABELS.ClinicalAges[value],
    }))
  return _clinicalAgeOptions
}

let _genderIdentityOptions: IValueOptions<GenderIdentity>
export const getGenderIdentityOptions = () => {
  _genderIdentityOptions =
    _genderIdentityOptions ||
    sortOnProperty(
      Object.values(GenderIdentity).map((value) => ({
        value,
        label: LABELS.GenderIdentity[value],
      })),
      'label',
    )
  return _genderIdentityOptions
}

let _raceOptions: IValueOptions<Race>
export const getRaceOptions = () => {
  _raceOptions =
    _raceOptions ||
    sortOnProperty(
      Object.values(Race).map((value) => ({
        value,
        label: LABELS.Race[value],
      })),
      'label',
    )
  return _raceOptions
}

let _ethnicityOptions: IValueOptions<Ethnicity>
export const getEthnicityOptions = () => {
  _ethnicityOptions =
    _ethnicityOptions ||
    sortOnProperty(
      Object.values(Ethnicity).map((value) => ({
        value,
        label: LABELS.Ethnicity[value],
      })),
      'label',
    )
  return _ethnicityOptions
}

let _legalSexOptions: IValueOptions<LegalSex>
export const getLegalSexOptions = () => {
  _legalSexOptions =
    _legalSexOptions ||
    sortOnProperty(
      Object.values(LegalSex).map((value) => ({
        value,
        label: LABELS.LegalSex[value],
      })),
      'label',
    )
  return _legalSexOptions
}

let _providerServiceTypeOptions: IValueOptions<ProviderServiceType>
export const getProviderServiceTypeOptions = () => {
  _providerServiceTypeOptions =
    _providerServiceTypeOptions ||
    sortOnProperty(
      Object.values(ProviderServiceType).map((value) => ({
        value,
        label: LABELS.ProviderServiceType[value],
      })),
      'label',
    )
  return _providerServiceTypeOptions
}

let _communityIdentityOptions: IValueOptions<CommunityIdentity>
export const getCommunityIdentityOptions = () => {
  _communityIdentityOptions =
    _communityIdentityOptions ||
    sortOnProperty(
      Object.values(CommunityIdentity).map((value) => ({
        value,
        label: LABELS.CommunityIdentity[value],
      })),
      'label',
    )
  return _communityIdentityOptions
}

let _dayOfWeekOptions: IValueOptions<DayOfWeek>
export const getDayOfWeekOptions = () => {
  _dayOfWeekOptions =
    _dayOfWeekOptions ||
    [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday,
      DayOfWeek.Saturday,
      DayOfWeek.Sunday,
    ].map((s) => ({
      value: s,
      label: LABELS.DayOfWeek[s],
    }))
  return _dayOfWeekOptions
}

let _dayPartOptions: IValueOptions<DayPart>
export const getDayPartOptions = () => {
  _dayPartOptions =
    _dayPartOptions ||
    Object.values(DayPart).map((s) => ({
      value: s,
      label: LABELS.DayPart[s],
    }))
  return _dayPartOptions
}

let _patientPreferencesTimes: IValueOptions
export const getPatientPreferenceTimeOptions = () => {
  _patientPreferencesTimes =
    _patientPreferencesTimes ||
    generateTimeOptions(15, { min: 7, max: 18 }).map(
      ({ display, iso }): IValueOption => ({
        label: display,
        value: iso,
      }),
    )
  return _patientPreferencesTimes
}

let _patientPreferenceDurations: IValueOptions<number>
export const getPatientPreferenceDurationOptions = () => {
  _patientPreferenceDurations =
    _patientPreferenceDurations ||
    [15, 20, 30, 45, 60, 90].map(
      (value): IValueOption<number> => ({ label: `${value} Min`, value }),
    )
  return _patientPreferenceDurations
}

let _providerIdentifierTypeOptions: IValueOption<ProviderIdentifierType>[]
export const getProviderIndentifierTypeOptions = () => {
  _providerIdentifierTypeOptions =
    _providerIdentifierTypeOptions ||
    sortOnProperty(
      Object.values(ProviderIdentifierType).map((v: ProviderIdentifierType) => {
        return {
          value: v,
          label: LABELS.providerIdentifierType[v],
        }
      }),
      'label',
    )
  return _providerIdentifierTypeOptions
}
