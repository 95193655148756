import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { toZonedTime } from '@valerahealth/ui-components/utils/date'
import OOOBanner from './OOOBanner'
import HistoricOOO from './HistoricOOO'
import { deleteOutOfOffice, loadOutOfOffice } from '../../api/providerAPI'
import {
  addUserMessage,
  displayConfirmation,
  editOOO,
} from '../../redux/actions/globalActions'
import dStrings from '../../strings.json'
import { getDefaultRangeDates } from '../AddEditProvider/domain'
import { loadProgramProviders } from '../../redux/actions/programActions'
import { isWellnessSelector } from '../../redux/selectors'
import './style.less'

function OutOfOffice({
  provider,
  providersList,
  user,
  displayConfirmation,
  editOOO,
  loadProgramProviders,
  addUserMessage,
}) {
  const [data, setData] = useState()
  const [bannerData, setBannerData] = useState()
  const [historicData, setHistoricData] = useState()
  const [firstLoad, setFirstLoad] = useState(true)
  const [timeZone, setTimeZone] = useState()
  const [rowCount, setRowCount] = useState()
  const [lastParams, setLastParams] = useState()
  const { isWellness } = useSelector(isWellnessSelector)

  useEffect(() => {
    if (!providersList) {
      loadProgramProviders()
    }
  }, [])

  useEffect(() => {
    setTimeZone(user && user.notifications && user.notifications.timeZone)
  }, [user])

  const loadData = (id, pagination) => {
    setLastParams({ id, pagination })
    loadOutOfOffice(id, pagination).then((response) => {
      const rc =
        response.header && response.header['x-total-items']
          ? parseInt(response.header['x-total-items'])
          : undefined

      setRowCount(rc)
      setData(response.data)
      setBannerData(response.data.next)
      // setBannerData();
      setHistoricData(response.data.all)
    })
  }

  const deleteDates = (params) => {
    displayConfirmation({
      details: {
        title: dStrings.deleteOOO,
        content: <div>{dStrings.deleteOOOConfirmation}</div>,
        cancelBtnText: dStrings.cancel,
        confirmBtnText: dStrings.delete,
      },
      callback: () => {
        deleteOutOfOffice(provider.id, params.id)
          .then(() => {
            addUserMessage('success', dStrings.oooSuccessfullyRemoved)
            loadData(provider.id, lastParams.pagination)
          })
          .catch(() => {
            addUserMessage('error', dStrings.errorOccurred)
          })
      },
    })
  }

  useEffect(() => {
    if (provider && provider.id) {
      const [sd, ed] = getDefaultRangeDates()
      loadData(provider.id, { startDate: sd, endDate: ed })
    }
  }, [provider && provider.id])

  const handleGetData = (pagination) => {
    if (firstLoad) {
      setFirstLoad(false)
      return
    }

    loadData(provider.id, pagination)
  }

  return (
    <>
      <OOOBanner
        data={bannerData}
        timeZone={timeZone}
        providers={providersList}
        handleDelete={deleteDates}
        editable={
          isWellness && process.env.PROJECT_DISABLE_ADD_OOO === 'true'
            ? { active: false, covering: false }
            : undefined
        }
        handleEdit={() => {
          editOOO({
            id: provider.id,
            outOfOfficeData: {
              ...bannerData,
              startDate: toZonedTime(bannerData.startDate, timeZone),
              endDate: toZonedTime(bannerData.endDate, timeZone),
            },
            user,
            handleChange: () => {
              loadData(lastParams.id, lastParams.pagination)
            },
          })
        }}
      />
      {provider?.id && (
        <div style={{ marginTop: bannerData ? '24px' : '0px' }}>
          <HistoricOOO
            id={provider.id}
            data={
              historicData
                ? historicData.map((h) => ({
                    ...h,
                    startDate: toZonedTime(h.startDate, timeZone),
                    endDate: toZonedTime(h.endDate, timeZone),
                  }))
                : []
            }
            providersList={providersList}
            rowCount={rowCount}
            user={user}
            handleDelete={deleteDates}
            handleGetData={handleGetData}
          />
        </div>
      )}
    </>
  )
}

OutOfOffice.propTypes = {}
function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    providersList: state.program.careTeam?.allIds.map(
      (id) => state.program.careTeam.byId[id],
    ),
  }
}

const mapDispatchToProps = {
  displayConfirmation,
  editOOO,
  loadProgramProviders,
  addUserMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(OutOfOffice)
