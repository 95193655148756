// this file should contain very common words that most apps will use

import {
  ClinicalAges,
  AppointmentStatus,
  AppointmentType,
  DayOfWeek,
  DocumentCategory,
  ServiceCategoryCode,
  ServiceTypeCode,
  EmploymentRole,
  PayerEnrollmentStatus,
  PatientStatus,
  PatientGenderIdentity,
  PatientLegalSex,
  PatientRelationshipToInsured,
  IdentificationType,
  PatientRelationshipToIdCard,
  Race,
  Ethnicity,
  InsurancePlanLineOfBusiness,
  GenderIdentity,
  Availability,
  ProviderIdentifierType,
  EmploymentType,
  LegalSex,
  EmploymentStatus,
  ActiveStatus,
  ProviderServiceType,
  CommunityIdentity,
  DayPart,
  TaskType,
  TaskStatus,
  TaskServiceType,
} from '@valerahealth/rtk-query'

export default {
  logout: 'Logout', // noun
  logOut: 'Log Out', // verb
  signOut: 'Sign Out', // verb
  support: 'Support',
  add: 'Add',
  remove: 'Remove',
  loading: 'Loading',
  complete: 'Complete',
  failed: 'Failed',
  replace: 'Replace',
  upload: 'Upload',
  ok: 'Ok',
  yes: 'Yes',
  no: 'No',
  save: 'Save',
  saveAs: 'Save As',
  cancel: 'Cancel',
  confirm: 'Confirm',
  warning: 'Warning',
  delete: 'Delete',
  edit: 'Edit',
  view: 'View',
  close: 'Close',
  clear: 'Clear',
  next: 'Next',
  back: 'Back',
  approved: 'Approved',
  submitted: 'Submitted',
  submit: 'Submit',
  none: 'None',
  other: 'Other',
  unavailable: 'Unavailable',
  timezone: 'Timezone',
  timeZone: 'Time Zone',
  nextgenTimezone: 'NextGen Timezone',
  nextgenTimezoneHelperText:
    'Do not update this timezone until the Virtual Visit timezone is set as well as the NextGen and Valera Schedule Templates are also in sync with the updated timezone.',
  nextgenTimezoneIs: 'NextGen timezone is {{nextgenTimezone}}',
  time: 'Time',
  image: 'Image',
  providers: 'Providers',
  onboarding: 'Onboarding',
  credentialing: 'Credentialing',
  system: 'System',
  reports: 'Reports',
  resources: 'Resources',
  home: 'Home',
  legalSex: 'Legal Sex',
  city: 'City',
  state: 'State',
  zipCode: 'Zip Code',
  postalCode: 'Postal Code',
  stateOfResidence: 'State of Residence',
  search: 'Search',
  user: 'User',
  profile: 'Profile',
  demographicsNotes: 'Profile Notes',
  license: 'License',
  licenses: 'Licenses',
  insurance: 'Insurance',
  insurances: 'Insurances',
  insurancePlan: 'Insurance Plan',
  insurancePlans: 'Insurance Plans',
  supervision: 'Supervision',
  scheduling: 'Scheduling',
  name: 'Name',
  firstName: 'First Name',
  middleName: 'Middle Name',
  suffix: 'Suffix',
  lastName: 'Last Name',
  password: 'Password',
  ssn: 'SSN',
  preferredName: 'Preferred Name',
  priorLastName: 'Prior Last Name',
  pronoun: 'Pronoun',
  pronouns: 'Pronouns',
  gender: 'Gender',
  genderIdentity: 'Gender Identity',
  settings: 'Settings',
  role: 'Role',
  type: 'Type',
  email: 'Email',
  emailAddress: 'Email Address',
  address: 'Address',
  phone: 'Phone',
  phoneNumber: 'Phone Number',
  title: 'Title',
  status: 'Status',
  hours: 'Hours',
  hour: 'Hour',
  duration: 'Duration',
  numberedHours_one: '$t(hour)',
  numberedHours_other: '$t(hours)',
  employment: 'Employment',
  race: 'Race',
  dayOfWeek: 'Day of Week',
  ethnicity: 'Ethnicity',
  clinicalAges: 'Clinical Ages',
  clinicalAge: 'Clinical Age',
  clinicalModalities: 'Clinical Modalities',
  clinicalModality: 'Clinical Modality',
  clinicalSpecialties: 'Clinical Specialties',
  clinicalSpecialty: 'Clinical Specialty',
  communityIdentity: 'Community Identity',
  spirituality: 'Spirituality',
  languages: 'Languages',
  language: 'Language',
  location: 'Location',
  locations: 'Locations',
  profession: 'Profession',
  careCoordinator: 'Care Coordinator',
  dayPart: 'Day Part',
  notes: 'Notes',
  note: 'Note',
  collapse: 'Collapse',
  more: 'More',
  columns: 'Columns',
  licenseType: 'License Type',
  licenseLocation: 'License Location',
  ages: 'Ages',
  valera: 'Valera',
  history: 'History',
  modalities: 'Modalities',
  speciality: 'Specialty',
  specialty: 'Specialty',
  specialties: 'Specialties', // american english spelling
  specialities: 'Specialties', // note this is the british spelling which our israeli & india friends like to use :)
  supervisor: 'Supervisor',
  startDate: 'Start Date',
  endDate: 'End Date',
  startTime: 'Start Time',
  endTime: 'End Time',
  comment: 'Comment',
  billingSupervisors: 'Billing Providers',
  billingSupervisor: 'Billing Provider',
  supervisees: 'Supervisees',
  availability: 'Availability',
  availabilityStatus: 'Availability Status',
  availabilityNotes: 'Availability Notes',
  serviceTypes: 'Service Types',
  serviceLocations: 'Schedule Locations',
  genderIdentities: 'Gender Identities',
  races: 'Races',
  ethnicities: 'Ethnicities',
  spiritualities: 'Spiritualities',
  communityIdentities: 'Community Identities',
  patientPreferences: 'Patient Preferences',
  PatientPreferencesField: {},
  form_invalid: 'There are one or more errors in the form.',
  form_invalid_field: 'Invalid {{field}}.',
  form_invalid_email: 'Please enter a valid email address.',
  form_required_field: '{{field}} is required.',
  form_invalid_field_min:
    '{{field}} needs to have a value greater than or equal to {{min}}.',
  form_invalid_field_max:
    '{{field}} needs to have a value less than or equal to {{max}}.',
  form_invalid_field_min_date: '{{field}} needs to be on or after {{min}}.',
  form_invalid_field_max_date: '{{field}} needs to be on or before {{max}}.',
  form_invalid_field_minLength_one: '{{field}} must be at least one character.',
  form_invalid_field_maxLength_one: '{{field}} must be one character.',
  form_invalid_field_minLength_other:
    '{{field}} must be at least {{count}} characters.',
  form_invalid_field_maxLength_other:
    '{{field}} must be less than or equal to {{count}} characters.',
  form_invalid_field_characters:
    '{{field}} cannot contains special characters: / ” [ ] : | < > = ; , ? * @.',
  form_date1MustBeGreaterThanDate2: '{{date1}} must be after {{date2}}.',
  form_date1MustBeLessThanDate2: '{{date1}} must be before {{date2}}.',
  form_invalidDate: 'The date is not valid.',
  form_invalidFutureDate: '{{date}} should not be a future date.',
  serverError: 'Server Error',
  contactSupport:
    'Please try again and if the problem persists, contact support by emailing provider-support@valerahealth.com.',
  genericActionError: 'Something went wrong. $t(contactSupport)',
  appName: {
    careManager: 'Care Manager',
    practiceManager: 'Practice Manager',
    backoffice: 'Backoffice',
    userAdmin: 'User Administration',
  },
  weekdays: 'Weekdays',
  DayOfWeek: {
    [DayOfWeek.Monday]: 'Monday',
    [DayOfWeek.Tuesday]: 'Tuesday',
    [DayOfWeek.Wednesday]: 'Wednesday',
    [DayOfWeek.Thursday]: 'Thursday',
    [DayOfWeek.Friday]: 'Friday',
    [DayOfWeek.Saturday]: 'Saturday',
    [DayOfWeek.Sunday]: 'Sunday',
    abbreviated: {
      [DayOfWeek.Monday]: 'mon',
      [DayOfWeek.Tuesday]: 'tue',
      [DayOfWeek.Wednesday]: 'wed',
      [DayOfWeek.Thursday]: 'thu',
      [DayOfWeek.Friday]: 'fri',
      [DayOfWeek.Saturday]: 'sat',
      [DayOfWeek.Sunday]: 'sun',
    },
  },
  networkError: {
    '401': '$t(serverError): 401 - Unauthorized',
    '403': '$t(serverError): 403 - Forbidden',
    '400': '$t(serverError): 400 - Bad Request .',
    '409':
      '$t(serverError): 409 - Someone else is editing the same data as you.',
    '422':
      '$t(serverError): 422 - Validation Error, the request made is not formatted properly.',
    '500': '$t(serverError): 500 - Something went wront.',
    '502': '$t(serverError): 502 - Bad Gateway.',
    '503': '$t(serverError): 503 - Service Unavailable.',
    '504': '$t(serverError): 503 - Gateway Timeout.',
    unknown: 'There was an issue retrieving data.',
  },
  schedule_conflict: 'Schedule Conflict',
  appt_conflict: 'Appt Conflict',
  copied_sucessfully: 'Copied sucessfully',
  serviceType: 'Service Type',
  bulkUpdate: 'Bulk Update',
  active: 'Active',
  requests: 'Requests',
  uploaded: 'Uploaded',
  modified: 'Modified',
  documents: 'Documents',
  DocumentUpload: {
    label: 'Upload Document(s)',
    success: 'Document(s) upload successfully',
  },
  DocumentDelete: {
    header: 'Delete Document',
    body: 'Are you sure you want to delete the document?',
    success: 'Document deleted successfully',
  },
  DocumentUpdate: {
    fileNameValidation: 'File Name is required',
    success: 'Document updated successfully',
    error: 'Document could not be updated',
  },
  category: 'Category',
  Availability: {
    [Availability.Open]: 'Open',
    [Availability.Pending]: 'Pending',
    [Availability.Closed]: 'Closed',
    [Availability.WaitlistOnly]: 'Waitlist Only',
    [Availability.CareCoordinatorOnly]: 'Care Coordinator Only',
    [Availability.OpenPrioritized]: 'Open (prioritized)',
    [Availability.OpenFlexible]: 'Open (flexible)',
    [Availability.Recruiting]: 'Recruiting',
    [Availability.PilotOnly]: 'Pilot Only',
  },
  documentCategory: {
    [DocumentCategory.Attestation]: 'Attestation',
    [DocumentCategory.ClinicalAcknowledgement]: 'Clinical Acknowledgement',
    [DocumentCategory.ClinicalLicense]: 'Clinical License',
    [DocumentCategory.ClinicalRegistration]: 'Clinical Registration',
    [DocumentCategory.Cme]: 'CME',
    [DocumentCategory.Education]: 'Education',
    [DocumentCategory.DeaLicense]: 'DEA License',
    [DocumentCategory.Id]: 'ID',
    [DocumentCategory.ProfessionalLiabilityInsurance]:
      'Professional Liability Insurance',
    [DocumentCategory.PrimaryLiabilityInsurance]: 'Primary Liability Insurance',
    [DocumentCategory.Report]: 'Report',
    /** These are deprecated but data exists in lower envs to leaving here for a while */
    [DocumentCategory.ReportCaqhSummary]: 'Reports and Verifications',
    [DocumentCategory.ReportCmsOptOut]: 'Reports and Verifications',
    [DocumentCategory.ReportNpdbQuery]: 'Reports and Verifications',
    [DocumentCategory.ReportNppesOrNpi]: 'Reports and Verifications',
    [DocumentCategory.ReportOfac]: 'Reports and Verifications',
    [DocumentCategory.ReportOig]: 'Reports and Verifications',
    [DocumentCategory.ReportSam]: 'Reports and Verifications',
    [DocumentCategory.ReportSsnvsFile]: 'Reports and Verifications',
    /** END deprecated */
    [DocumentCategory.ReportsAndVerifications]: 'Reports and Verifications',
    [DocumentCategory.Specialty]: 'Board Certificate/Specialty',
    [DocumentCategory.Other]: 'Other',
  },
  providerIdentifierType: {
    [ProviderIdentifierType.Npi]: 'NPI',
    [ProviderIdentifierType.PrimaryCmsTaxonomy]: 'Primary CMS Taxonomy',
    [ProviderIdentifierType.SecondaryCmsTaxonomy]: 'Secondary CMS Taxonomy',
    [ProviderIdentifierType.CaqhId]: 'CAQH ID',
    [ProviderIdentifierType.HrId]: 'HR ID',
    [ProviderIdentifierType.NextgenId]: 'NextGen ID',
    [ProviderIdentifierType.NextgenResourceId]: 'NextGen Resource ID',
    [ProviderIdentifierType.LumaId]: 'Luma ID',
  },
  serviceCategory: 'Schedule Type',
  LegalSex: {
    [LegalSex.Male]: 'Male',
    [LegalSex.Female]: 'Female',
  },
  ActiveStatus: {
    [ActiveStatus.Active]: 'Active',
    [ActiveStatus.Inactive]: 'Inactive',
  },
  EmploymentStatus: {
    [EmploymentStatus.Active]: 'Active',
    [EmploymentStatus.Inactive]: 'Inactive',
    [EmploymentStatus.Pending]: 'Pending',
    [EmploymentStatus.Resigning]: 'Resigning',
    [EmploymentStatus.Onleave]: 'On Leave',
  },
  EmploymentType: {
    [EmploymentType.FullTime]: 'Full Time',
    [EmploymentType.PartTime]: 'Part Time',
    [EmploymentType.Contract]: 'Contract',
  },
  EmploymentRole: {
    [EmploymentRole.CareCoordinator]: 'Care Coordinator',
    [EmploymentRole.Therapist]: 'Therapist',
    [EmploymentRole.TherapistSupervisor]: 'Supervising Therapist',
    [EmploymentRole.Prescriber]: 'Prescriber',
    [EmploymentRole.PrescriberSupervisor]: 'Supervising Prescriber',
    [EmploymentRole.MedicalAssistant]: 'Medical Assistant',
    [EmploymentRole.CaseManager]: 'Case Manager',
    [EmploymentRole.HealthConnector]: 'Health Connector',
    [EmploymentRole.AssociateTherapist]: 'Associate Therapist',
    [EmploymentRole.GroupTherapy]: 'Group Therapy',
  },
  ServiceCategoryCode: {
    [ServiceCategoryCode.Patient]: 'Patient Hours',
    CASE_MANAGEMENT: 'Case Mgmt Hours',
    [ServiceCategoryCode.Toc]: 'TOC Hours',
    [ServiceCategoryCode.Admin]: 'Admin Hours',
    [ServiceCategoryCode.Break]: 'Break Hours',
    [ServiceCategoryCode.OutOfOffice]: 'Out of Office',
    CONSULT: 'Consult Hours',
    [ServiceCategoryCode.Cap]: 'A&F Hours',
    [ServiceCategoryCode.Group]: 'Group Hours',
    [ServiceCategoryCode.Intake]: 'Intake Hours',
    expected: 'Expected {{serviceCategory}}',
  },
  ServiceCategoryCodeShorthand: {
    [ServiceCategoryCode.Patient]: 'Patient',
    CASE_MANAGEMENT: 'Case Mgmt',
    [ServiceCategoryCode.Toc]: 'TOC',
    [ServiceCategoryCode.Admin]: 'Admin',
    [ServiceCategoryCode.Break]: 'Break',
    [ServiceCategoryCode.OutOfOffice]: 'OOO',
    CONSULT: 'Consult',
    [ServiceCategoryCode.Cap]: 'A&F',
    [ServiceCategoryCode.Intake]: 'Intake',
    [ServiceCategoryCode.Group]: 'Group',
  },
  ServiceCategoryCodeAppointment: {
    [ServiceCategoryCode.Patient]: 'Patient Appointment',
    CASE_MANAGEMENT: 'Case Mgmt Appointment',
    [ServiceCategoryCode.Toc]: 'TOC Appointment',
    [ServiceCategoryCode.Admin]: 'Admin Appointment',
    [ServiceCategoryCode.Break]: 'Break Appointment',
    [ServiceCategoryCode.OutOfOffice]: 'Out of Office Appointment',
    CONSULT: 'Consult Appointment',
    [ServiceCategoryCode.Cap]: 'A&F Appointment',
    [ServiceCategoryCode.Group]: 'Group Appointment',
    [ServiceCategoryCode.Intake]: 'Intake Appointment',
  },
  DayPart: {
    [DayPart.morning]: 'Morning',
    [DayPart.afternoon]: 'Afternoon',
    [DayPart.evening]: 'Evening',
  },
  ServiceTypeCode: {
    /** keeping old traslations just in case data has these */
    PED: 'Initial Pediatric',
    INC: 'Incident to',
    F20: 'Follow-up 20',
    TOC: 'TOC',
    CAP: 'A&F Hours',
    CAP_INT: 'A&F Initial',
    CMG: 'Case Mgmt',
    CMG_TRC: 'Case Mgmt - Transitional Care',
    DIP: 'Dyadic In Person',
    /** end old translations */
    [ServiceTypeCode.Adm]: 'Admin Appointment',
    [ServiceTypeCode.Brk]: 'Break Appointment',
    CON: 'Valera Health Consult',
    [ServiceTypeCode.Dbt]: 'Therapy - DBT - Follow-up',
    [ServiceTypeCode.DbtInt]: 'Therapy - DBT - Initial',
    [ServiceTypeCode.Emdr]: 'Therapy - EMDR - Follow-up',
    [ServiceTypeCode.EmdrInt]: 'Therapy - EMDR - Initial',
    [ServiceTypeCode.F30]: 'Therapy - Follow-up 30',
    [ServiceTypeCode.F45]: 'Therapy - Follow-up 45',
    [ServiceTypeCode.F60]: 'Therapy - Follow-up 60',
    [ServiceTypeCode.Grp]: 'Group Therapy - Follow-up',
    [ServiceTypeCode.GrpInt]: 'Group Therapy - Initial',
    [ServiceTypeCode.Int]: 'Therapy - Initial',
    [ServiceTypeCode.Int45]: 'Therapy - Psychosocial - Initial',
    [ServiceTypeCode.LocInt]: 'Therapy - Level of Care Assessment - Initial',
    [ServiceTypeCode.Medrec]: 'Medication Reconciliation',
    [ServiceTypeCode.Other]: 'Other',
    [ServiceTypeCode.PsyInt]: 'Psychiatry - Initial',
    [ServiceTypeCode.PsyCapInt]: 'Psychiatry - A&F - Initial',
    [ServiceTypeCode.PsyInc]: 'Psychiatry - Incident-to',
    [ServiceTypeCode.PsyF20]: 'Psychiatry - Follow-up 20',
    [ServiceTypeCode.PsyF30]: 'Psychiatry - Follow-up 30',
    [ServiceTypeCode.PsyF45]: 'Psychiatry - Follow-up 45',
    [ServiceTypeCode.PsyF60]: 'Psychiatry - Follow-up 60',
    [ServiceTypeCode.PsyTrf]: 'Psychiatry - Case Transfer',
    [ServiceTypeCode.PsyTocInt]: 'Psychiatry - TOC - Initial',
    [ServiceTypeCode.TocInt]: 'Therapy - TOC - Initial',
    [ServiceTypeCode.Trf]: 'Therapy - Case Transfer',
    [ServiceTypeCode.Tif]: 'Therapy - Intake Transfer',
  },
  ServiceTypeCodeAcronym: {
    /** keeping old traslations just in case data has these */
    PED: 'Initial Pediatric',
    INC: 'INC',
    F20: 'F20',
    TOC: 'TOC',
    CAP: 'A&F',
    CAP_INT: 'A&F-INT',
    CMG: 'CMG',
    CMG_TRC: 'CMG-TRC',
    DIP: 'DIP',
    /** end old translations */
    [ServiceTypeCode.Adm]: 'ADMIN',
    [ServiceTypeCode.Brk]: 'BREAK',
    CON: 'CON',
    [ServiceTypeCode.Dbt]: 'DBT',
    [ServiceTypeCode.DbtInt]: 'DBT-INT',
    [ServiceTypeCode.Emdr]: 'EMDR',
    [ServiceTypeCode.EmdrInt]: 'EMDR-INT',
    [ServiceTypeCode.F30]: 'F30',
    [ServiceTypeCode.F45]: 'F45',
    [ServiceTypeCode.F60]: 'F60',
    [ServiceTypeCode.Grp]: 'GRP',
    [ServiceTypeCode.GrpInt]: 'GRP-INT',
    [ServiceTypeCode.Int]: 'INT',
    [ServiceTypeCode.Int45]: 'INT45',
    [ServiceTypeCode.LocInt]: 'LOC-INT',
    [ServiceTypeCode.Medrec]: 'MED-REC',
    [ServiceTypeCode.Other]: 'OTHER',
    [ServiceTypeCode.PsyInt]: 'PSY-INT',
    [ServiceTypeCode.PsyCapInt]: 'PSY-A&F-INT',
    [ServiceTypeCode.PsyInc]: 'PSY-INC',
    [ServiceTypeCode.PsyF20]: 'PSY-F20',
    [ServiceTypeCode.PsyF30]: 'PSY-F30',
    [ServiceTypeCode.PsyF45]: 'PSY-F45',
    [ServiceTypeCode.PsyF60]: 'PSY-F60',
    [ServiceTypeCode.PsyTrf]: 'PSY-TRF',
    [ServiceTypeCode.PsyTocInt]: 'PSY-TOC-INT',
    [ServiceTypeCode.TocInt]: 'TOC-INT',
    [ServiceTypeCode.Trf]: 'TRF',
    [ServiceTypeCode.Tif]: 'TIF',
  },
  AppointmentType: {
    [AppointmentType.Checkup]: 'Checkup',
    [AppointmentType.Emergency]: 'Emergency',
    [AppointmentType.Followup]: 'Followup',
    [AppointmentType.Routine]: 'Routine',
  },
  AppointmentStatus: {
    [AppointmentStatus.Booked]: 'Scheduled',
    [AppointmentStatus.Canceled]: 'Canceled',
    [AppointmentStatus.Canceled_24]: 'Canceled < 24 hrs',
    [AppointmentStatus.Canceled_48]: 'Canceled < 48 hrs',
    [AppointmentStatus.EnteredInError]: 'Canceled - Entered in Error',
    [AppointmentStatus.Fulfilled]: 'Completed',
    [AppointmentStatus.Noshow]: 'No Show',
    [AppointmentStatus.Pending]: 'Pending',
    [AppointmentStatus.Proposed]: 'Proposed',
    [AppointmentStatus.Rescheduled]: 'Rescheduled',
    [AppointmentStatus.Waitlist]: 'Waitlist',
  },
  CommunityIdentity: {
    [CommunityIdentity.Bipoc]: 'BIPOC',
    [CommunityIdentity.Lgbtqia]: 'LGBTQIA+',
  },
  EditAppointmentStatus: {
    [AppointmentStatus.Canceled]: 'Cancel',
    [AppointmentStatus.Canceled_48]: 'Cancel < 48 Hrs',
    [AppointmentStatus.Fulfilled]: 'Complete',
    [AppointmentStatus.Noshow]: 'No Show',
    [AppointmentStatus.Rescheduled]: 'Reschedule',
  },
  PayerEnrollmentStatus: {
    [PayerEnrollmentStatus.NeedToApply]: 'Need to Apply',
    [PayerEnrollmentStatus.AwaitingPrerequisite]: 'Awaiting Prerequisite',
    [PayerEnrollmentStatus.Na]: 'N/A',
    [PayerEnrollmentStatus.SubmittedToPlan]: 'Submitted to Plan',
    SUBMITTED_TO_MEDALLION: 'Submitted to Medallion',
    [PayerEnrollmentStatus.NaNonResident]: 'NA - Non-Resident',
    [PayerEnrollmentStatus.MissingInfo]: 'Missing Information',
    [PayerEnrollmentStatus.OnHold]: 'On Hold',
    [PayerEnrollmentStatus.EmailedRequest]: 'Emailed Request',
    [PayerEnrollmentStatus.PendingReassignment]: 'Pending Reassignment',
    [PayerEnrollmentStatus.ParAndLinkedToGroup]: 'PAR and Linked to Group',
  },
  GenderIdentity: {
    [GenderIdentity.Male]: 'Man',
    [GenderIdentity.Female]: 'Woman',
    [GenderIdentity.NonBinary]: 'Non-binary',
    [GenderIdentity.TransMale]: 'Transgender-Man',
    [GenderIdentity.TransFemale]: 'Transgender-Woman',
    [GenderIdentity.Genderqueer]: 'Genderqueer',
    [GenderIdentity.Other]: 'Other',
    [GenderIdentity.NonDisclose]: 'Prefer not to disclose',
  },
  PatientStatus: {
    [PatientStatus.Active]: 'Active',
    [PatientStatus.AwaitingInitial]: 'Awaiting Initial',
    [PatientStatus.ClosedDischarged]: 'Discharged',
    dischargedDoNotReadmit: 'Don’t Readmit',
    dischargedNeverInService: 'Never in Service',
    [PatientStatus.Pending]: 'Pending',
    [PatientStatus.Waitlist]: 'Waitlist',
    [PatientStatus.Lead]: 'Lead',
  },
  PatientGenderIdentity: {
    [PatientGenderIdentity.Male]: 'Male',
    [PatientGenderIdentity.Female]: 'Female',
    [PatientGenderIdentity.NonBinary]: 'Non Binary',
    [PatientGenderIdentity.TransgenderMale]: 'Transgender Male',
    [PatientGenderIdentity.TransgenderFemale]: 'Transgender Female',
    [PatientGenderIdentity.Genderqueer]: 'Gender Queer',
    [PatientGenderIdentity.Other]: 'Other',
    [PatientGenderIdentity.NoneDisclose]: 'Non Disclose',
  },
  PatientLegalSex: {
    [PatientLegalSex.M]: 'Male',
    [PatientLegalSex.F]: 'Female',
    [PatientLegalSex.O]: 'Other',
  },
  PatientRelationshipToInsured: {
    [PatientRelationshipToInsured.Self]: 'Self',
    [PatientRelationshipToInsured.ParentGuardian]: 'Parent/Guardian',
    [PatientRelationshipToInsured.Spouse]: 'Spouse',
    [PatientRelationshipToInsured.Other]: 'Other',
  },
  IdentificationType: {
    [IdentificationType.DriversLicense]: 'Drivers License',
    [IdentificationType.Passport]: 'Passport',
    [IdentificationType.StateId]: 'State ID',
  },
  PatientRelationshipToIdCard: {
    [PatientRelationshipToIdCard.Self]: `$t(PatientRelationshipToInsured.${[
      PatientRelationshipToInsured.Self,
    ]})`,
    [PatientRelationshipToIdCard.ParentGuardian]: `$t(PatientRelationshipToInsured.${[
      PatientRelationshipToInsured.ParentGuardian,
    ]})`,
  },
  Race: {
    [Race.Native]: 'American Indian or Alaska Native',
    [Race.Asian]: 'Asian',
    [Race.Black]: 'Black or African American',
    [Race.PacificIslander]: 'Native Hawaiian or Other Pacific Islander',
    [Race.White]: 'White',
  },
  Ethnicity: {
    [Ethnicity.Hispanic]: 'Hispanic or Latino',
    [Ethnicity.NotHispanic]: 'Not Hispanic or Latino',
  },
  InsurancePlanLineOfBusiness: {
    [InsurancePlanLineOfBusiness.Commercial]: 'Commercial',
    [InsurancePlanLineOfBusiness.Medicare]: 'Medicare',
    [InsurancePlanLineOfBusiness.Medicaid]: 'Medicaid',
    [InsurancePlanLineOfBusiness.Autoinsurance]: 'Other-Auto Insurance',
    [InsurancePlanLineOfBusiness.Veteransaffairs]: 'Other-Veterans Affairs',
    [InsurancePlanLineOfBusiness.Workerscomp]: 'Other-Workers Comp',
  },
  verify: 'verify',
  unVerify: 'un-verify',
  verified: 'Verified',
  verification: 'Verification',
  ClinicalAges: {
    [ClinicalAges.peds]: 'Peds (6-12)',
    [ClinicalAges.teens]: 'Teens/Ado (13-17)',
    [ClinicalAges.adult]: 'Adults (18-64)',
    [ClinicalAges.geriatric]: 'Geriatric (65+)',
  },

  reviewed: 'Reviewed',
  comments: 'Comments',
  country: 'Country',
  birthCountry: 'Birth Country',
  streetAddress: 'Street Address',
  didYouComplete: 'Did you complete?',
  deleteConfirmation: 'Delete Confirmation',
  invalidZipCode: 'Zip Code must be a 5 digit number.',
  areYouAUSCitizen: 'Are you a US Citizen?',
  personalEmail: 'Personal Email',
  primaryPhone: 'Primary Phone',
  dateOfBirth: 'Date Of Birth',
  streetAddressOne: 'Street Address 1',
  accountType: 'Account Type',
  userName: 'Username',
  copy: 'Copy',
  date: 'Date',
  ProviderServiceType: {
    CASE_MGMT: 'Case Mgmt',
    [ProviderServiceType.MedMgmt]: 'Psychiatry',
    [ProviderServiceType.GroupTherapy]: 'Group Therapy',
    [ProviderServiceType.Therapy]: 'Therapy',
  },
  details: 'Details',
  billing: 'Billing',
  addAppointment: 'Add Appointment',
  editAppointment: 'Edit Appointment',
  appointmentStatus: 'Appointment Status',
  myPractice: 'My Practice',
  myCalendar: 'My Calendar',
  tasks: 'Tasks',
  task: {
    tooltip: '',
    taskType: {
      [TaskType.ApptCancelation]: 'Appt Cancelation',
      [TaskType.ApptRequest]: 'Appt Request',
      [TaskType.ApptReschedule]: 'Appt Reschedule',
      [TaskType.AttendanceAgreement]: 'Attendance Agreement',
      [TaskType.Billing]: 'Billing',
      [TaskType.CaseTransfer]: 'Transfer (Intake)',
      [TaskType.Discharge]: 'Discharge',
      [TaskType.Grievance]: 'Grievance',
      [TaskType.MedRecordRequest]: 'Med Record Request',
      [TaskType.MissingInfo]: 'Missing Info',
      [TaskType.NewPatientSelf]: 'New Patient - Self',
      [TaskType.NewPatientReferral]: 'New Patient - Referral',
      [TaskType.NewPatientCallCenter]: 'New Patient - Call Center',
      [TaskType.NewPatientToc]: 'New Patient - TOC',
      [TaskType.NewPatientWaitlist]: 'Waitlist',
      [TaskType.Other]: 'Other',
      [TaskType.ReferralToExternal]: 'Referral to External',
      [TaskType.ReferralToInternal]: 'Referral to Internal',
      [TaskType.TechIssue]: 'Tech Issue',
      [TaskType.TransitionsOfCare]: 'Transitions of Care',
      [TaskType.InsuranceVerification]: 'Insurance Verification',
      [TaskType.DeaClearance]: 'DEA Clearance',
      [TaskType.NewPatientCallReferral]: 'New Patient - Call Referral',
      [TaskType.PriorAuthorization]: 'Prior Authorization',
      [TaskType.RefillRequest]: 'Refill Request',
      [TaskType.PatientTransfer]: 'Transfer (Patient)',
    },
    taskStatus: {
      [TaskStatus.Completed]: 'Completed',
      [TaskStatus.Overdue]: 'Overdue',
      [TaskStatus.ToDo]: 'To Do',
      [TaskStatus.Void]: 'Void',
    },
    taskServiceType: {
      CASE_MGMT: 'Case Mgmt',
      [TaskServiceType.GroupTherapy]: 'Group Therapy',
      [TaskServiceType.MedMgmt]: 'Psychiatry',
      [TaskServiceType.Therapy]: 'Therapy',
    },
    fail_save_task: 'Failed to save task',
    fail_update_task: 'Failed to update task',
    fail_delete_task: 'Failed to delete task',
    fail_change_status: 'Failed to change status',
    success_task_save: 'Task was successfully saved',
    success_delete_task: 'Task was successfully deleted',
    success_tasks_update:
      'Tasks were successfully updated. Please allow 60 seconds for a data refresh.',
  },
  cm_not_authorized_discharge: 'Only care team member can do discharge action',
  sendAppLink: 'Send App Download link',
  patients: 'Patients',
  primaryEmail: 'Primary Email',
  tags: 'Tags',
  PatientAppInviteForm: {
    sendInvSuccess: 'Invitation sent.',
    sendInvError: 'An error occured while sending app invitation.',
  },
  DischargePopup: {
    dischargePatientError: 'Failed to discharge the patient.',
    dischargePatientWarning:
      'You are about to discharge a patient. This action will revoke the patient’s access to the mobile application, and remove the patient and their data from any active lists on this clinic.',
    dischargePatientSuccess: 'Successfully discharged the patient.',
  },
  EmrIdPopupButton: {
    title: 'Edit EMR Identifiers',
  },
  addNewPatient: 'Add New Patient',
  addToClinic: 'ADD TO CLINIC',
  dischargePatient: 'Discharge Patient',
  registerWithExistingPhoneNumber: 'Register With Existing Phone Number',
  PatientSearch: {
    registerToEMRSuccess:
      'Successfully registered to EMR, please wait a few minutes to see the change reflect in grid.',
    registerToEMRError: 'Failed to register to EMR.',

    restorePatientSuccess:
      'Successfully restored the patient, please wait a few minutes to see the change reflect in grid.',
    restorePatientError: 'Failed to restore the patient.',
    copyLinkSuccess: 'Link copied.',
    dischargePatient: 'Discharge Patient',
    startVideoCall: 'Start Video Call',
  },
  value: 'Value',
}
