import { createAction, createReducer } from '@reduxjs/toolkit'
import { type View } from 'react-big-calendar'
import { CalendarDensity, type CalendarStates } from './Calendar.type'
import { getUSTimezone } from '../../utils/date'

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

export const initialState: CalendarStates = {
  timezone: getUSTimezone(urlParams.get('timezone')),
  view: 'week',
  calenderDate: urlParams.get('calendarDate') || new Date().toISOString(),
  calendarDensity:
    (localStorage.getItem('calendarDensity') as CalendarDensity) || 'STANDARD',
}

export const actions = {
  changeTimezone: createAction<string>(
    'uiComponentsCalendarState_changeTimezone',
  ),
  changeView: createAction<View>('uiComponentsCalendarState_changeView'),
  changeCalendarDate: createAction<string>(
    'uiComponentsCalendarState_changeCalendarDate',
  ),
  changeCalendarDensity: createAction<CalendarDensity>(
    'uiComponentsCalendarState_changeCalendarDensity',
  ),
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.changeTimezone, (state, action) => {
      state.timezone = action.payload
    })
    .addCase(actions.changeView, (state, action) => {
      state.view = action.payload
    })
    .addCase(actions.changeCalendarDate, (state, action) => {
      state.calenderDate = action.payload
    })
    .addCase(actions.changeCalendarDensity, (state, action) => {
      state.calendarDensity = action.payload
    })
})
