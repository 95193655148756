import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NoResultIcon from '../../../../images/no_result.svg'
import NoResultSearchIcon from '../../../../images/no_result_search.svg'
import AddTreatmentIcon from '../../../../images/add_treatment.svg'
import DischargeIcon from '../../../../images/Group.svg'
import './style.css'
import dStrings from '../../../../strings.json'
import { caseloadStateEnum } from '../../../utilities/enums'
import { useReduxSelector } from '../../../../redux/store'
import { userAdminSelector } from '../../../../redux/selectors'

function NoTreatment({
  caseloadState,
  noSearchResult,
  noTreatment,
  pending,
}) {
  const { permissions} = useReduxSelector(userAdminSelector)
  
  const getIcon = () => {
    if (caseloadState === caseloadStateEnum.DISCHARGE) return <DischargeIcon />
    if (noSearchResult) return <NoResultSearchIcon />
    if (noTreatment) return <AddTreatmentIcon />
    return <NoResultIcon />
  }

  const getMessages = () => {
    if (caseloadState === caseloadStateEnum.DISCHARGE)
      return <div>{dStrings.noDischarge}</div>
    if (caseloadState !== caseloadStateEnum.LEAD) {
      if (noSearchResult)
        return (
          <>
            <div>{dStrings.noResultSearch}</div>
            <div>{dStrings.checkSpelling}</div>
          </>
        )
      if (noTreatment)
        return (
          <>
            <div>{dStrings.noPatient}</div>
            {permissions?.addPatients && <div>{dStrings.registerPatientMessage}</div>}
          </>
        )
      return <div>{dStrings.noFilterResult}</div>
    }
    if (pending.allIds && !pending.allIds.length) {
      return <div>{dStrings.noPengindPatient}</div>
    }

    return <div>{dStrings.noFilterResult}</div>
  }

  return (
    <div className="no-treatment">
      {getIcon()}
      <div className="no-treatment-message">{getMessages()}</div>
    </div>
  )
}

NoTreatment.propTypes = {
  noTreatment: PropTypes.bool,
  noSearchResult: PropTypes.bool,
}

function mapStateToProps(state) {
  const servers = state.caseload.filters && state.caseload.filters.server

  return {
    caseloadState: state.caseload.caseloadState,
    pending: state.caseload.pending,
    noTreatment: servers && !Object.keys(servers).length,
    noSearchResult: servers && servers.name,
  }
}
export default connect(mapStateToProps, null)(NoTreatment)
