import { useState, useCallback } from 'react'
import { isWellnessSelector } from 'redux/selectors'
import { useReduxSelector } from 'redux/store'
import {
  PopupMenu,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useNotify,
  useConfirmationDialog,
  useFeatureFlagNoStartSession,
  EditEmrIdPopupButton,
  AppInvitePopup,
  DischargePopup,
  RegisterToEmr,
} from '@valerahealth/ui-components'
import ExportPopup from 'components/common/ExportPopup/'
import {
  MoreVertRounded,
  ContentCopy,
  AttachEmail,
  Block,
  ReplayCircleFilled,
  Cable,
} from '@valerahealth/ui-components/icons'
import { PatientStatus, careManagerApi } from '@valerahealth/rtk-query'

import { checkPermission, Permission } from '@valerahealth/redux-auth'
import { useTranslation } from '@valerahealth/ui-translation'
import ExportIcon from '../../../images/icn_export.svg'
import { exportChannel } from '../../../api/treatmentAPI'
import { downloadContent } from '../../utilities'

interface TreatmentActionsMenuProps {
  treatmentId: string
  firstName: string
  lastName: string
  mrn?: string | null
  phone: string
  email: string
  videoLink?: string | null
  status: PatientStatus
  emrId?: string | null
  lumaId?: string | null
  nextgenId?: string | null
  isIntegrationActive?: boolean | null
  disableExport?: boolean
}

const { useRestorePatientMutation } = careManagerApi

export function useTreatmentActionsMenu() {
  const [t] = useTranslation()
  const notify = useNotify()
  const canUpdateEmrId = useReduxSelector((state) =>
    checkPermission(state, Permission.PatientIntegration_Update),
  )
  const isWellness = useReduxSelector(isWellnessSelector)
  const [restorePatient, restorePatientStatus] = useRestorePatientMutation()
  const { ConfirmationDialog, confirm } = useConfirmationDialog()
  const hideStartSessionButton = useFeatureFlagNoStartSession()
  const [modal, setModal] = useState<
    | null
    | {
        type: 'invite'
        treatmentId: string
        phone: string
        email: string
      }
    | { type: 'discharge'; treatmentId: string }
    | { type: 'export'; treatmentId: string }
  >(null)

  return {
    TreatmentActionsMenu: useCallback(
      ({
        treatmentId,
        firstName,
        lastName,
        mrn,
        phone,
        email,
        videoLink,
        status,
        emrId,
        lumaId,
        nextgenId,
        isIntegrationActive,
        disableExport,
      }: TreatmentActionsMenuProps) => {
        return (
          <PopupMenu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            button={
              <IconButton title="Expand" sx={{ mr: 1 }}>
                <MoreVertRounded />
              </IconButton>
            }
          >
            {!hideStartSessionButton &&
              status !== PatientStatus.ClosedDischarged &&
              videoLink && (
                <MenuItem
                  onClick={() => {
                    navigator.clipboard.writeText(videoLink)
                    notify({
                      severity: 'success',
                      message: t('PatientSearch.copyLinkSuccess'),
                    })
                  }}
                >
                  <ListItemIcon>
                    <ContentCopy />
                  </ListItemIcon>
                  <ListItemText>Copy Session Link</ListItemText>
                </MenuItem>
              )}

            {isWellness &&
              !emrId &&
              status !== PatientStatus.ClosedDischarged &&
              status !== PatientStatus.Lead && (
                <RegisterToEmr treatmentId={treatmentId} />
              )}

            {canUpdateEmrId && (
              <EditEmrIdPopupButton
                treatmentId={treatmentId}
                patientProfile={{
                  firstName,
                  lastName,
                  mrn,
                }}
                integration={{
                  emrId,
                  lumaId,
                  nextgenId,
                  isActive: !!isIntegrationActive,
                }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <Cable />
                  </ListItemIcon>
                  <ListItemText primary={t('EmrIdPopupButton.title')} />
                </MenuItem>
              </EditEmrIdPopupButton>
            )}

            {!disableExport && (
              <MenuItem
                onClick={() => {
                  setModal({ type: 'export', treatmentId })
                }}
              >
                <ListItemIcon>
                  <ExportIcon />
                </ListItemIcon>
                <ListItemText primary={t('exportChannel')} />
              </MenuItem>
            )}
            {status !== PatientStatus.ClosedDischarged && (
              <MenuItem
                onClick={() => {
                  setModal({
                    treatmentId,
                    type: 'invite',
                    phone,
                    email,
                  })
                }}
              >
                <ListItemIcon>
                  <AttachEmail />
                </ListItemIcon>
                <ListItemText>Send App Invite</ListItemText>
              </MenuItem>
            )}
            {status !== PatientStatus.ClosedDischarged && (
              <MenuItem
                onClick={() => {
                  setModal({
                    type: 'discharge',
                    treatmentId,
                  })
                }}
              >
                <ListItemIcon>
                  <Block color="error" />
                </ListItemIcon>
                <ListItemText color="error">Discharge Patient</ListItemText>
              </MenuItem>
            )}
            {status === PatientStatus.ClosedDischarged && (
              <MenuItem
                disabled={restorePatientStatus.isLoading}
                onClick={async () => {
                  const confirmed = await confirm({
                    header: t('restorePatient'),
                    body: t('restorePatientConfirmation'),
                    confirmLabel: t('Yes'),
                    cancelLabel: t('No'),
                  })

                  if (confirmed) {
                    await restorePatient(treatmentId).then((value) => {
                      if ('data' in value) {
                        notify({
                          severity: 'success',
                          message: t('PatientSearch.restorePatientSuccess'),
                        })
                      } else {
                        notify({
                          severity: 'error',
                          message: t('PatientSearch.restorePatientError'),
                        })
                        console.error(value.error)
                      }
                    })
                  }
                }}
              >
                <ListItemIcon>
                  <ReplayCircleFilled />
                </ListItemIcon>
                <ListItemText>Restore Patient</ListItemText>
              </MenuItem>
            )}
          </PopupMenu>
        )
      },
      [
        canUpdateEmrId,
        confirm,
        hideStartSessionButton,
        isWellness,
        notify,
        restorePatient,
        restorePatientStatus.isLoading,
        t,
      ],
    ),
    TreatmentActionsMenuPopups: useCallback(() => {
      return (
        <>
          <ConfirmationDialog />
          <AppInvitePopup
            open={modal?.type === 'invite'}
            onCancel={() => setModal(null)}
            onSuccess={() => {
              setModal(null)
            }}
            {...(modal?.type === 'invite'
              ? modal
              : { email: '', phone: '', treatmentId: '' })}
          />
          <DischargePopup
            open={modal?.type === 'discharge'}
            onCancel={() => setModal(null)}
            onSuccess={() => {
              setModal(null)
            }}
            {...modal}
          />
          {modal?.type === 'export' && (
            <ExportPopup
              title={t('exportChannel')}
              warning={t('exportChannelWarning')}
              onClosed={() => {
                setModal(null)
              }}
              handleSave={(_start: Date, _end: Date) => {
                exportChannel(
                  modal.treatmentId,
                  _start.toISOString(),
                  _end.toISOString(),
                ).then(({ url }) => {
                  downloadContent(url, t('exportChannel'))
                })
                setModal(null)
              }}
            />
          )}
        </>
      )
    }, [ConfirmationDialog, modal, t]),
  }
}
