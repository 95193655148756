// styling guide:
// MD: https://www.figma.com/file/9KkBNF0L03vJK1nlSKExhT/Valera-DS-V.1?node-id=6649%3A50219
// Calendar Figma: https://www.figma.com/file/a7yx0Uo35yUivf28g1f6lK/Provider-Provider-Scheduling-Draft

import { useMemo, useCallback, useLayoutEffect, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import overlap from 'react-big-calendar/lib/utils/layout-algorithms/overlap'
import {
  Calendar as RbCalendar,
  dateFnsLocalizer,
  type Event,
} from 'react-big-calendar'
import { format } from 'date-fns'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import { toZonedTime } from 'date-fns-tz'
import { Box } from '../../base'
import { type ICalendarProps } from './Calendar.type'
import { CalendarMonthDateHeader } from './MonthDateHeader'
import { CalendarHeaderCell } from './CalendarHeaderCell'
import {
  CalendarContextProvider,
  CalendarContextProviderProps,
  useCalendarContext,
} from './CalendarContext'

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: {
    locale: enUS,
  },
})

export const CALENDAR_TIMESLOT_HEIGHT_PX = 96

function Calendar<TEvent extends Event, TResource extends object>({
  events,
  backgroundEvents,
  sx,
  components,
  selectable,
  ...rest
}: ICalendarProps<TEvent, TResource>) {
  const {
    selectCalendarState,
    actions: { changeCalendarDate, changeView: onView },
  } = useCalendarContext()
  const { view, timezone, calenderDate, calendarDensity } =
    selectCalendarState()

  const [timeSlotHeight, setTimeSlotHeight] = useState(
    CALENDAR_TIMESLOT_HEIGHT_PX,
  )

  useLayoutEffect(() => {
    let tsHeight = CALENDAR_TIMESLOT_HEIGHT_PX
    if (calendarDensity === 'STANDARD') tsHeight = CALENDAR_TIMESLOT_HEIGHT_PX
    if (calendarDensity === 'COMPACT')
      tsHeight = CALENDAR_TIMESLOT_HEIGHT_PX - 62
    if (calendarDensity === 'COMFORTABLE')
      tsHeight = CALENDAR_TIMESLOT_HEIGHT_PX - 30

    setTimeSlotHeight(tsHeight)
  }, [calendarDensity])

  const onNavigate = useCallback(
    (newDate: Date) => changeCalendarDate(newDate.toDateString()),
    [changeCalendarDate],
  )

  const onDrillDown = useCallback(
    (date: Date) => {
      changeCalendarDate(date.toISOString())
      onView(view === 'month' ? 'week' : 'day')
    },
    [onView, changeCalendarDate, view],
  )

  const zonedEvents = useMemo(
    () =>
      events?.map(
        ({ start, end, ...event }) =>
          ({
            ...event,
            start: start ? toZonedTime(start, timezone) : undefined,
            end: end ? toZonedTime(end, timezone) : undefined,
          } as TEvent),
      ),
    [events, timezone],
  )

  const zonedBackgroundEvents = useMemo(
    () =>
      backgroundEvents?.map(
        ({ start, end, ...event }) =>
          ({
            ...event,
            start: start ? toZonedTime(start, timezone) : undefined,
            end: end ? toZonedTime(end, timezone) : undefined,
          } as TEvent),
      ),
    [backgroundEvents, timezone],
  )

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '0px',
        '.rbc-calendar': {
          flexGrow: 1,
        },
        '.rbc-time-view': {
          border: 'none',
          overflowY: 'auto',
        },
        '.rbc-day-slot .rbc-events-container': {
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          marginRight: '0px',
          top: 0,
          pointerEvents: 'none',
        },
        '.rbc-timeslot-group': {
          borderBottom: (theme) => `1px solid ${theme.palette.text.primary}`,
          minHeight: `${timeSlotHeight}px`,
          display: 'flex',
          flexFlow: 'column nowrap',
        },
        '.rbc-day-slot .rbc-event': {
          border: '0px',
          pointerEvents: 'all',
        },
        '.rbc-day-slot': {
          opacity: 1,
          border: '0px',
          borderRadius: '0px',
          padding: '0px 0px',
          color: (theme) => theme.palette.text.primary,
          minHeight: '0px',
        },
        '.rbc-background-event-title': {
          display: 'block',
          marginTop: '0.25rem',
          marginLeft: '0.25rem',
        },
        '.rbc-event': {
          color: (theme) => theme.palette.text.primary,
          borderRadius: '0px',
          backgroundColor: 'transparent',
          padding: '0',
        },
        '.rbc-background-event': {
          color: (theme) => theme.palette.text.primary,
          backgroundColor: 'transparent',
          padding: 0,
          borderRadius: 0,
          border: 0,
        },
        '.rbc-event-label': {
          height: '0px',
          visibility: 'hidden',
        },
        '.rbc-day-slot .rbc-event-label': {
          paddingRight: '0px',
          visibility: 'hidden',
        },
        '.rbc-day-slot .rbc-time-slot': {
          borderWidth: '2px 0px 0px 0px',
          borderColor: 'transparent',
          borderStyle: 'solid',
          cursor: selectable ? 'pointer' : 'initial',
          transition: 'border-color .25s',
          '&:hover': {
            borderColor: (theme) =>
              selectable ? theme.palette.primary.dark : 'transparent',
          },
        },
        '.rbc-month-row': {
          minHeight: '7.5rem',
        },
        '.rbc-month-view': {
          height: 'auto',
          overflowY: 'auto',
        },
        '.rbc-date-cell': {
          flex: '1 1',
          minWidth: '0',
          paddingLeft: '5px',
          textAlign: 'left',
        },
        '.rbc-off-range-bg': {
          backgroundColor: 'transparent',
        },
        '.rbc-today': {
          backgroundColor: 'transparent',
        },
        '.rbc-time-view .rbc-row': {
          minHeight: 'auto',
        },
        '.rbc-time-header-cell-single-day': {
          display: 'inherit',
        },
        '.rbc-current-time-indicator': {
          backgroundColor: (theme) => theme.palette.error.light,
        },
        '.rbc-time-gutter .rbc-timeslot-group': {
          borderBottom: '0px',
        },
        '.rbc-time-content': {
          borderTop: 'none',
        },
        '.rbc-label': {
          color: (theme) => theme.palette.action.active,
          paddingLeft: 0,
        },
        '.rbc-time-slot > .rbc-label': {
          fontSize: '0.75em',
          lineHeight: '1.5em',
          position: 'relative',
          top: '-1.2em',
        },
        '.rbc-header, .rbc-time-header-gutter': {
          height: '2.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: (theme) => theme.palette.divider,
        },
        '.rbc-time-header-gutter': {
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          padding: 0,
        },
        ...sx,
      }}
    >
      <RbCalendar
        culture="locales"
        date={new Date(calenderDate)}
        scrollToTime={new Date()}
        onNavigate={onNavigate}
        onDrillDown={onDrillDown}
        defaultDate={new Date()}
        events={zonedEvents}
        backgroundEvents={zonedBackgroundEvents}
        view={view}
        views={{ day: true, week: true, month: true }}
        onView={onView}
        // fixes 15 minute overlap issue - https://github.com/jquense/react-big-calendar/issues/1530
        dayLayoutAlgorithm={(params) => {
          return overlap({ ...params, minimumStartDifference: 15 })
        }}
        defaultView="week"
        getNow={() => toZonedTime(new Date(), timezone)}
        localizer={localizer}
        components={{
          timeGutterHeader: AccessTimeIcon,
          header: CalendarHeaderCell,
          ...components,
          month: {
            dateHeader: CalendarMonthDateHeader,
            ...components?.month,
          },
        }}
        endAccessor={({ end }) => {
          const time = toZonedTime(end!, timezone)
          // If its an appt that runs to midnight, subtract one millisecond so it doesn't get put in the top bar
          if (time.getHours() + time.getMinutes() === 0) {
            return new Date(end!.getTime() - 1)
          }
          return end!
        }}
        selectable={selectable}
        {...rest}
        step={15}
        timeslots={4}
      />
    </Box>
  )
}

export default function CalendarWithProvider<
  TEvent extends Event,
  TResource extends object,
>({
  selectCalendarState,
  useDispatch,
  ...rest
}: CalendarContextProviderProps & ICalendarProps<TEvent, TResource>) {
  return (
    <CalendarContextProvider {...{ selectCalendarState, useDispatch }}>
      <Calendar {...rest} />
    </CalendarContextProvider>
  )
}
