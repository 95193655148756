import { createSelector } from '@reduxjs/toolkit'
import {
  configApi,
  mapStandardListItemToStrings,
  userAdminApi,
} from '@valerahealth/rtk-query'
import { type RootState } from './store'

export const programIdSelector = (state: RootState) =>
  state.auth.session?.user.programId || ''

export const userAdminSelector = (state: RootState) => {
  const { data, isError, isLoading } = userAdminApi.endpoints.getUser.select({
    email: state.auth.session?.user.email || '',
  })(state)

  return {
    isError,
    isLoading,
    profile: {
      id: data?.getUser.careManager.id,
      profileId: data?.getUser.careManager.careManagerId,
      email: data?.getUser.careManager.email,
    },
    permissions: data?.getUser.careManager.permissions,
  }
}

export const configApiProgramSettingsSelector =
  configApi.endpoints.getProgramSettings.select()

export const configProgramSettingsSelector = (state: RootState) => ({
  data: configApiProgramSettingsSelector(state).data?.getProgramSettings,
  isError: configApiProgramSettingsSelector(state).isError,
  isSuccess: configApiProgramSettingsSelector(state).isSuccess,
})

export const configFlagsSelector = createSelector(
  configProgramSettingsSelector,
  (settings) => ({
    isWellness: !!settings?.data?.flags?.isWellness,
    clinicalNotesActive: !!settings?.data?.flags?.clinicalNotesActive,
    oooCoveringProviderActive:
      !!settings?.data?.flags?.oooCoveringProviderActive,
    disablePatientChatToggleActive:
      !!settings?.data?.providerSettings?.chat?.disablePatientChatToggleActive,
    patientChatDisabledByDefault:
      !!settings?.data?.providerSettings?.chat?.patientChatDisabledByDefault,
    unreadChatActive: !!settings?.data?.providerSettings?.chat?.unreadChatActive,
    unreadEvents: settings?.data?.providerSettings?.chat?.unreadEvents || [],
    highlightedWords: settings?.data?.providerSettings?.chat?.highlightedWords || [],
    goalReminders: mapStandardListItemToStrings(
      settings?.data?.lists?.goalReminderTemplates || [],
    ),
  }),
)

export const isWellnessSelector = createSelector(
  configProgramSettingsSelector,
  (settings) => !!settings?.data?.flags?.isWellness,
)

const CARE_MANAGER_MAIN_NAV_DEFAULT = {
  calendarHidden: false,
  caseloadHidden: false,
  caseloadLeadsHidden: true,
  patientsHidden: false,
  tasksHidden: true,
  tracksHidden: false,
  supervisionHidden: false,
  myPracticeHidden: false,
}

export const mainNavigationLayoutSelector = createSelector(
  configProgramSettingsSelector,
  ({ data, isError, isSuccess }) => {
    const calendar = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'calendar',
    )
    const caseload = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'caseload',
    )
    const caseloadLeads = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'caseload-leads',
    )
    const patients = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'patients',
    )
    const tasks = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'tasks',
    )
    const tracks = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'tracks',
    )
    const supervision = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'supervision',
    )
    const myPractice = data?.layout?.careManagerMainNav?.find(
      (tab) => tab.id === 'my-practice',
    )

    if (isError) return CARE_MANAGER_MAIN_NAV_DEFAULT
    if (!isSuccess) {
      return {
        calendarHidden: true,
        caseloadHidden: true,
        caseloadLeadsHidden: true,
        patientsHidden: true,
        tasksHidden: true,
        tracksHidden: true,
        supervisionHidden: true,
        myPracticeHidden: true,
      }
    }

    return {
      calendarHidden: calendar
        ? calendar.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.calendarHidden,
      caseloadHidden: caseload
        ? caseload.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.caseloadHidden,
      caseloadLeadsHidden: caseloadLeads
        ? caseloadLeads.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.caseloadLeadsHidden,
      patientsHidden: patients
        ? patients.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.patientsHidden,
      tasksHidden: tasks
        ? tasks.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.tasksHidden,
      tracksHidden: tracks
        ? tracks.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.tracksHidden,
      supervisionHidden: supervision
        ? supervision.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.supervisionHidden,
      myPracticeHidden: myPractice
        ? myPractice.hidden
        : CARE_MANAGER_MAIN_NAV_DEFAULT.myPracticeHidden,
    }
  },
)

const CARE_MANAGER_PATIENT_NAV_DEFAULT = {
  timelineHidden: false,
  basicInfoHidden: false,
  billingHidden: true,
  alertsHidden: false,
  appointmentsHidden: false,
  tracksHidden: false,
  noteHidden: false,
  careTeamHidden: false,
  documentsHidden: false,
  dataHidden: false,
}

export const patientNavigationLayoutSelector = createSelector(
  configProgramSettingsSelector,
  ({ data, isError, isSuccess }) => {
    const timeline = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'timeline',
    )
    const basicInfo = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'basic-info',
    )
    const billing = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'billing',
    )
    const alerts = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'alerts',
    )
    const appointments = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'appointments',
    )
    const tracks = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'plan',
    )
    const note = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'note',
    )
    const careTeam = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'careteam',
    )
    const documents = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'documents',
    )
    const dataTab = data?.layout?.careManagerPatientNav?.find(
      (tab) => tab.id === 'data',
    )

    if (isError) return CARE_MANAGER_PATIENT_NAV_DEFAULT
    if (!isSuccess) {
      return {
        timelineHidden: true,
        basicInfoHidden: true,
        billingHidden: true,
        alertsHidden: true,
        appointmentsHidden: true,
        tracksHidden: true,
        noteHidden: true,
        careTeamHidden: true,
        documentsHidden: true,
        dataHidden: true,
      }
    }

    return {
      timelineHidden: timeline
        ? timeline.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.timelineHidden,
      basicInfoHidden: basicInfo
        ? basicInfo.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.basicInfoHidden,
      billingHidden: billing
        ? billing.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.billingHidden,
      alertsHidden: alerts
        ? alerts.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.alertsHidden,
      appointmentsHidden: appointments
        ? appointments.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.appointmentsHidden,
      tracksHidden: tracks
        ? tracks.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.tracksHidden,
      noteHidden: note
        ? note.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.noteHidden,
      careTeamHidden: careTeam
        ? careTeam.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.careTeamHidden,
      documentsHidden: documents
        ? documents.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.documentsHidden,
      dataHidden: dataTab
        ? dataTab.hidden
        : CARE_MANAGER_PATIENT_NAV_DEFAULT.dataHidden,
    }
  },
)
