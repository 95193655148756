/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-debugger */
/* eslint-disable no-continue */
/* eslint-disable react/no-array-index-key */
/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import './style.css'
import EyeIcon from '../../../images/eye.svg'
import { configFlagsSelector } from '../../../redux/selectors'
import { editItem } from '../../../redux/actions/globalActions'
import dStrings from '../../../strings.json'
import { getSelectedCount } from '../../utilities/treatments'
import { getCustomStyles } from '../../utilities'
import { getToolTemplateIcon } from '../../utilities/planTemplate'

const { Option, SingleValue } = components

function IconSelected(props) {
  const { data } = props
  const { value, label } = data
  return (
    <SingleValue {...props}>
      <span className="react-select-option-icon">
        {getToolTemplateIcon(value)}
      </span>
      <span style={{ marginLeft: '10px' }}>{label}</span>
    </SingleValue>
  )
}

function IconOption(props) {
  const { data } = props
  const { value, label } = data
  return (
    <Option {...props}>
      <span className="react-select-option-icon">
        {getToolTemplateIcon(value)}
      </span>
      <span style={{ marginLeft: '10px' }}>{label}</span>
    </Option>
  )
}

function AddEditItem({ item, handleSaveItem, bulkAction, disableType }) {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { toolsTemplates, displayAllItems, selectedCount } =
    useSelector((state) => {
      return {
        toolsTemplates: state.toolsTemplates,
        displayAllItems:
          !~pathname.indexOf('/templates') && !~pathname.indexOf('/plan'),
        selectedCount: getSelectedCount(state),
      }
    })
  
  const { goalReminders } = useSelector(configFlagsSelector) 

  const getTemplateList = () => {
    try {
      return toolsTemplates[selectedType.value].list.allIds.map((id) => {
        return toolsTemplates[selectedType.value].list.byId[id]
      })
    } catch (error) {
      console.log('tools templates not loaded')
    }
  }

  const getOptions = () => {
    const arr = []
    for (const key in toolsTemplates) {
      if (
        (!displayAllItems && toolsTemplates[key].hideInCarePlan) ||
        (bulkAction && toolsTemplates[key].hideInBulk) ||
        (toolsTemplates[key].type === 'select' &&
          toolsTemplates[key].list &&
          !toolsTemplates[key].list.allIds.length)
      )
        continue

      arr.push({
        value: toolsTemplates[key].id,
        label: toolsTemplates[key].title,
        icon: toolsTemplates[key].icon,
        type: toolsTemplates[key].type,
      })
    }

    return arr
  }

  const getSelectedTemplate = () => {
    if (
      item.info.templateId &&
      toolsTemplates[item.type].list.byId[item.info.templateId]
    ) {
      return {
        value: item.info.templateId,
        label:
          toolsTemplates[item.type].list.byId[item.info.templateId][
            templateKey
          ],
      }
    }

    const selectedOption = selectedType
      ? options.find((o) => o.value === selectedType.value)
      : options[0]
    const key = selectedType
      ? toolsTemplates[selectedType.value].key
      : templateKey

    if (selectedOption) {
      const { list } = toolsTemplates[selectedOption.value]
      if (list && list.byId) {
        return {
          value: list.allIds[0],
          label: list.byId[list.allIds[0]][key],
        }
      }
    }
  }

  const getSeletedType = () => {
    return item.type && toolsTemplates[item.type]
      ? {
          value: item.type,
          label: toolsTemplates[item.type].title,
          icon: getToolTemplateIcon(item.type),
          type: toolsTemplates[item.type].type,
        }
      : options[0]
  }

  const [options] = useState(getOptions)
  const [selectedType, setSelectedType] = useState(getSeletedType)
  const [text, setText] = useState(item.info.text || '')
  const [itemListName, setItemListName] = useState(getTemplateList)
  const [templateKey, setTemplateKey] = useState('name')
  const [categories, setCategories] = useState()
  const [selectedCategorie, setSelectedCategorie] = useState()
  const [selectedTemplate, setSelectedTemplate] = useState(getSelectedTemplate)

  useEffect(() => {
    const list = getTemplateList()
    setItemListName(list)
    setTemplateKey(toolsTemplates[selectedType.value].key)
    if (~['article', 'audio_track'].indexOf(selectedType.value)) {
      const obj = {}
      for (let i = 0; i < list.length; i++) {
        if (list[i].tags) {
          for (let y = 0; y < list[i].tags.length; y++) {
            if (!obj[list[i].tags[y]]) obj[list[i].tags[y]] = true
          }
        }
      }

      const keys = Object.keys(obj)
      keys.push(dStrings.all)
      keys.sort((a, b) => {
        const keyA = a.toLowerCase()
        const keyB = b.toLowerCase()
        if (keyA < keyB) return -1
        return 1
      })
      setCategories(keys)
      setSelectedCategorie(keys[0])
    } else if (selectedCategorie) {
      setCategories()
      setSelectedCategorie()
    }

    try {
      if (selectedType.type === 'select') {
        if (item.type !== selectedType.value) {
          const { list } = toolsTemplates[selectedType.value]
          if (
            !selectedTemplate ||
            list.byId[selectedTemplate.value] ||
            ~['article', 'audio_track'].indexOf(selectedType.value)
          )
            return
          setSelectedTemplate({
            value: list.allIds[0],
            label: list.byId[list.allIds[0]][templateKey],
          })
        }
      }
    } catch (error) {
      debugger
    }
  }, [selectedType.value])

  useEffect(() => {
    if (!selectedCategorie) return

    const { list } = toolsTemplates[selectedType.value]

    if (selectedCategorie !== dStrings.all) {
      const firstIdOfCategorie = list.allIds.find((id) => {
        return (
          list.byId[id].tags && ~list.byId[id].tags.indexOf(selectedCategorie)
        )
      })

      setSelectedTemplate({
        value: firstIdOfCategorie,
        label: list.byId[firstIdOfCategorie][templateKey],
      })
    } else if (list.allIds && list.allIds[0] && list.byId[list.allIds[0]]) {
      setSelectedTemplate({
        value: list.allIds[0],
        label: list.byId[list.allIds[0]][templateKey],
      })
    }
  }, [selectedCategorie])

  useEffect(() => {
    const info = {}

    if (selectedType.type === 'select') {
      if (!selectedTemplate) return
      info.templateId = selectedTemplate.value
    } else info.text = text

    handleSaveItem({
      actionItem: {
        type: selectedType.value,
        info,
      },
    })
  }, [selectedType, selectedTemplate, text])

  const handleChangeItemName = (e) => {
    setSelectedTemplate(e)
  }

  const handleChangeType = (sType) => {
    setSelectedType(sType)
  }

  const handleChangeCategory = (e) => {
    setSelectedCategorie(e.target.value)
  }

  const getTestDisplayedListItem = () => {
    if (
      selectedCategorie !== dStrings.all &&
      ~['article', 'audio_track'].indexOf(selectedType.value)
    ) {
      return (
        itemListName &&
        itemListName
          .filter((item) => item.tags && ~item.tags.indexOf(selectedCategorie))
          .map((k) => {
            return { value: k.id, label: k[templateKey] }
          })
      )
    }

    return (
      itemListName &&
      itemListName.map((k, i) => {
        return { value: k.id, label: k[templateKey] }
      })
    )
  }

  const customStyles = getCustomStyles({ height: '36px' })

  const getInput = () => {
    switch (toolsTemplates[selectedType.value].type) {
      case 'select':
        return (
          <div className="add-new-item-part with-open-eye">
            <label htmlFor="item-type">{dStrings.itemName}</label>
            <Select
              onChange={handleChangeItemName}
              value={selectedTemplate}
              styles={customStyles}
              options={getTestDisplayedListItem()}
            />
            <div className="eye-icon-container">
              <EyeIcon
                onClick={() => {
                  dispatch(
                    editItem({
                      type: selectedType.value,
                      info: {
                        id: selectedTemplate.value,
                        templateId: selectedTemplate.value,
                      },
                    }),
                  )
                }}
              />
            </div>
          </div>
        )

      case 'creatableSelect':
        return (
          <div className="add-new-item-part">
            <label htmlFor="item-type">{dStrings.messageText}</label>
            <CreatableSelect
              onChange={(e) => {
                setText(e.value)
              }}
              value={{ label: text, value: text }}
              styles={customStyles}
              options={goalReminders?.map((a) => {
                return { label: a, value: a }
              })}
            />
          </div>
        )

      case 'text':
        return (
          <div className="add-new-item-part">
            <label htmlFor="item-type">{dStrings.messageText}</label>
            <input
              placeholder={dStrings.enterMessage}
              type="text"
              // value={text}
              onChange={(e) => {
                setText(e.target.value)
              }}
            />
          </div>
        )
      default:
        break
    }
  }

  return (
    <div className="add-new-item-container">
      {bulkAction && selectedCount && (
        <div className="bulk-message">
          {dStrings.sendItemToPatients.replace(
            '${selectedCount}',
            selectedCount,
          )}
        </div>
      )}
      <div className="add-new-item-part">
        <label htmlFor="item-type">{dStrings.itemType}</label>
        <Select
          styles={customStyles}
          isDisabled={disableType}
          className="item-type-select"
          value={selectedType}
          onChange={handleChangeType}
          // menuIsOpen={true} //good for test
          components={{ Option: IconOption, SingleValue: IconSelected }}
          options={options}
          openOnFocus
          autofocus
        />
      </div>
      {categories && (
        <div className="add-new-item-part">
          <label htmlFor="item-type">{dStrings.category}</label>
          <select onChange={handleChangeCategory}>
            {categories.map((categorie, index) => {
              return (
                <option key={index} value={categorie}>
                  {categorie}
                </option>
              )
            })}
          </select>
        </div>
      )}
      {getInput()}
    </div>
  )
}

AddEditItem.propTypes = {
  item: PropTypes.object,
  handleSaveItem: PropTypes.func,
  disableType: PropTypes.bool,
}

export default AddEditItem
