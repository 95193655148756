import React, { useEffect, useRef, useState } from 'react'
import './style.less'
import { connect, useSelector } from 'react-redux'
import { CallLoader, ThreeStateCheckbox } from '@valerahealth/ui-core'
import TreatmentRow from './TreatmentRow'
import NoTreatment from './NoTreatment'
import '../../common/caseload-table.less'
import dStrings from '../../../strings.json'
import {
  loadCaseloadFilterNumbers,
  loadTreatments,
  selectAllTreatments,
  sortCaseload,
  unselectAllTreatments,
} from '../../../redux/actions/caseloadActions'
import { isWellnessSelector } from '../../../redux/selectors'
import { caseloadStateEnum, threeStateEnum } from '../../utilities/enums'

function TreatmentsList({
  treatments,
  treatmentsIds,
  showValues,
  caseloadState,
  sortDirection,
  cannotLoadMore,
  resetScroll,
  sortKey,
  selectAllTreatments,
  unselectAllTreatments,
  sortCaseload,
  loadTreatments,
}) {
  const STEP = 30
  const $table = useRef(null)
  const [allRowsSelected, setAllRowsSelected] = useState(
    threeStateEnum.UNCHECKED,
  )
  const [index, setIndex] = useState(STEP)
  const [isLoadingMore, setisLoadingMore] = useState(false)
  const { isWellness } = useSelector(isWellnessSelector)

  useEffect(() => {
    if (resetScroll) {
      $table.current.scrollTo(0, 0)
    }
  }, [resetScroll])

  useEffect(() => {
    if (!treatmentsIds) return

    let allSelected = true
    let allUnselected = true
    for (let i = 0; i < treatmentsIds.length; i++) {
      const id = treatmentsIds[i]

      if (treatments[id] && treatments[id].selected) allUnselected = false
      else allSelected = false
    }

    if (allUnselected) setAllRowsSelected(threeStateEnum.UNCHECKED)
    else if (allSelected) setAllRowsSelected(threeStateEnum.CHECKED)
    else setAllRowsSelected(threeStateEnum.INDETERMINATE)

    // setIdsToDisplay(treatmentsIds.slice(0, index));
  }, [treatments, treatmentsIds])

  const serverPagination = () => {
    if (
      !cannotLoadMore &&
      $table.current.scrollHeight - 10 - Math.ceil($table.current.scrollTop) <=
        $table.current.clientHeight &&
      !isLoadingMore
    ) {
      // loadCaseloadFilterNumbers(true);
      setisLoadingMore(true)
      loadTreatments({
        nextPage: true,
        hideLoader: true,
        callback: () => {
          setisLoadingMore(false)
        },
      })
    }
  }

  useEffect(() => {
    if (isLoadingMore) $table.current.scrollTop = $table.current.scrollHeight
  }, [isLoadingMore])

  useEffect(() => {
    if (!treatmentsIds) return

    setIndex(STEP)
  }, [treatmentsIds])

  // const clientPagination = () => {
  //   if (!treatmentsIds) return;

  //   if (
  //     ($table.current.offsetHeight + $table.current.scrollTop >=
  //     $table.current.scrollHeight) && index < treatmentsIds.length
  //   ) {
  //     const newIndex = index + STEP;
  //     setIndex(newIndex);
  //     setIdsToDisplay(treatmentsIds.slice(0, newIndex));
  //   }
  // };

  return (
    <div
      className="caseload-table-container"
      ref={$table}
      onScroll={
        caseloadState !== caseloadStateEnum.LEAD ? serverPagination : undefined
      }
    >
      {treatmentsIds && treatmentsIds.length ? (
        <>
          <table
            className={`caseload-table${
              caseloadState === caseloadStateEnum.DISCHARGE
                ? ' discharged-state'
                : ''
            }`}
          >
            <thead className="caseload-row header-row">
              <tr>
                {caseloadState === caseloadStateEnum.ACTIVE && (
                  <th
                    className={`treatment-select ${
                      allRowsSelected !== threeStateEnum.UNCHECKED
                        ? 'visible'
                        : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <ThreeStateCheckbox
                      value={allRowsSelected}
                      id="list-footer-checkbox"
                      handleChange={(newVal) => {
                        if (newVal) selectAllTreatments()
                        else unselectAllTreatments()
                      }}
                    />
                  </th>
                )}
                <th className="treatment-picture">{dStrings.Patient}</th>
                <th className="name-and-diagnosis" />
                {caseloadState === caseloadStateEnum.ACTIVE && (
                  <th className="treatment-details">
                    {dStrings.appointmentsAndScores}
                  </th>
                )}
                {caseloadState === caseloadStateEnum.ACTIVE &&
                  showValues.PopulationCarePlan && (
                    <th className="care-plan">{dStrings.PopulationCarePlan}</th>
                  )}
                {caseloadState === caseloadStateEnum.DISCHARGE && (
                  <th className="discharge-reason-container">
                    <div className="title">{dStrings.dischargeReason}</div>
                  </th>
                )}
                {caseloadState === caseloadStateEnum.DISCHARGE && (
                  <th className="discharge-reason-container">
                    <div className="title">{dStrings.dischargeDate}</div>
                  </th>
                )}
                {caseloadState !== caseloadStateEnum.LEAD ? (
                  <>
                    <th className="event-channel">{dStrings.notifications}</th>
                    <th className="treatment-row-options" />
                    <th className="treatment-link" />
                  </>
                ) : (
                  <>
                    <th className="pending-date">
                      {dStrings.pendingDate}{' '}
                      <span
                        className={`arrow-${
                          sortKey !== 'ts'
                            ? 'down'
                            : sortDirection
                            ? 'top'
                            : 'down'
                        }`}
                        onClick={() => {
                          sortCaseload('ts')
                        }}
                      />
                    </th>
                    <th className="pending-button">{}</th>
                  </>
                )}
                {Boolean(isWellness) && <th />}
              </tr>
            </thead>
            <tbody>
              {treatmentsIds.map((id, index) => {
                return treatments[id] ? (
                  <TreatmentRow key={id} treatment={treatments[id]} />
                ) : (
                  <></>
                )
              })}
            </tbody>
          </table>
          {isLoadingMore && (
            <div className="loading-more-container">
              <CallLoader />
            </div>
          )}
        </>
      ) : (
        <>{treatmentsIds && <NoTreatment />}</>
      )}
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const obj = {
    caseloadState: state.caseload.caseloadState,
    cannotLoadMore: state.caseload.cannotLoadMore,
    sortDirection: state.caseload.sortDirection,
    sortKey: state.caseload.sortKey,
    resetScroll: ownProps.resetScroll,
  }

  if (state.caseload.caseloadState === caseloadStateEnum.ACTIVE) {
    return {
      ...obj,
      treatments: state.caseload.byId,
      treatmentsIds: state.caseload.allIds,
      showValues: state.caseload.showValues,
    }
  }
  if (state.caseload.caseloadState === caseloadStateEnum.DISCHARGE) {
    if (state.caseload.discharged) {
      return {
        ...obj,
        treatments: state.caseload.discharged.byId,
        treatmentsIds:
          state.caseload.discharged.displayedIds ||
          state.caseload.discharged.allIds,
      }
    }
  } else if (state.caseload.pending) {
    return {
      ...obj,
      treatments: state.caseload.pending.byId,
      treatmentsIds:
        state.caseload.pending.displayedIds || state.caseload.pending.allIds,
    }
  }
  return obj
}

const mapDispatchToProps = {
  selectAllTreatments,
  unselectAllTreatments,
  sortCaseload,
  loadTreatments,
  loadCaseloadFilterNumbers,
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentsList)
