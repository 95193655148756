import { createSelector } from '@reduxjs/toolkit'
import { configApi } from '../services'
import { StandardListItem } from './generated.types'

export const configApiProgramSettingsSelector =
  configApi.endpoints.getProgramSettings.select()

export const isWellnessSelector = createSelector(
  configApiProgramSettingsSelector,
  (settings) => !!settings?.data?.getProgramSettings?.flags?.isWellness,
)

export const mapStandardListItemToStrings = (aggregates: StandardListItem[]) =>
  aggregates.reduce((list, aggregate) => {
    list.push(aggregate.value)
    return list
  }, [] as string[])

export const aggregatesSelector = createSelector(
  configApiProgramSettingsSelector,
  (settings) => ({
    isAggregateError: !!settings.error,
    isAggregateLoading: settings.isLoading,
    aggregates: {
      specialties: mapStandardListItemToStrings(
        settings.data?.getProgramSettings.providerSettings?.specialties || [],
      ),
      modalities: mapStandardListItemToStrings(
        settings.data?.getProgramSettings.providerSettings?.modalities || [],
      ),
      spirituality: mapStandardListItemToStrings(
        settings.data?.getProgramSettings.providerSettings?.spirituality || [],
      ),
      languages: mapStandardListItemToStrings(
        settings.data?.getProgramSettings.providerSettings?.languages || [],
      ),
    },
  }),
)
