import { useMemo } from 'react'
import { QueryDslBoolQuery } from '@valerahealth/rtk-query/lib/opensearch.types'
import { add } from '@valerahealth/ui-components/utils/date'
import { CircularProgress, Typography } from '@valerahealth/ui-components'
import { Check, WarningAmber } from '@valerahealth/ui-components/icons'
import {
  availabilityApi,
  ServiceCategoryCode,
  ServiceTypeCode,
} from '@valerahealth/rtk-query'
import { useFormContext } from '@valerahealth/ui-components/form'
import { FormType } from './formType'

type ContinuedAvailabilityMsgProps = {
  // in ISO, not users browser timezone
  startDateTimeIso: Date | null
}

export const ContinuedAvailabilityMsg = ({
  startDateTimeIso,
}: ContinuedAvailabilityMsgProps) => {
  const { watch } = useFormContext<FormType>()

  const [providerId, serviceType] = watch(['providerId', 'serviceType'])

  const query = useMemo((): QueryDslBoolQuery | null => {
    if (
      (serviceType &&
        ![ServiceTypeCode.Trf, ServiceTypeCode.Tif].includes(serviceType)) ||
      !startDateTimeIso ||
      !providerId
    )
      return null

    const end = add(startDateTimeIso, { minutes: 60 })

    return {
      must: [
        {
          term: {
            'provider.id.keyword': providerId,
          },
        },
        {
          term: {
            'serviceCategoryCode.keyword': ServiceCategoryCode.Patient,
          },
        },
        {
          // availability slot covers appt start-end 1 week out and two weeks out.
          bool: {
            should: [
              {
                bool: {
                  must: [
                    {
                      range: {
                        startDate: {
                          lte: add(startDateTimeIso, {
                            weeks: 1,
                          }).toISOString(),
                        },
                      },
                    },
                    {
                      range: {
                        endDate: {
                          gte: add(end, { weeks: 1 }).toISOString(),
                        },
                      },
                    },
                  ],
                },
              },
              {
                bool: {
                  must: [
                    {
                      range: {
                        startDate: {
                          lte: add(startDateTimeIso, {
                            weeks: 2,
                          }).toISOString(),
                        },
                      },
                    },
                    {
                      range: {
                        endDate: {
                          gte: add(end, { weeks: 2 }).toISOString(),
                        },
                      },
                    },
                  ],
                },
              },
            ],
            minimum_should_match: 1,
          },
        },
      ],
    }
  }, [serviceType, startDateTimeIso, providerId])

  const { isFetching, hasNextTwoWeeksAvailable } =
    availabilityApi.useTimeSlotSearchQuery(
      {
        query: query!,
        pageSize: 0,
      },
      {
        selectFromResult: ({ isFetching, data }) => ({
          isFetching,
          hasNextTwoWeeksAvailable:
            (data?.timeSlotSearch.resultCount ?? 0) >= 2,
        }),
        skip: !query,
      },
    )

  return query ? (
    <Typography sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
      {isFetching ? (
        <>
          <CircularProgress size="1.5rem" /> Searching for availability in
          subsequent weeks...
        </>
      ) : hasNextTwoWeeksAvailable ? (
        <>
          <Check color="success" fontSize="medium" /> Weekly slot is available
          the following 2 weeks.
        </>
      ) : (
        <>
          <WarningAmber color="warning" fontSize="medium" /> Weekly slot is not
          available the following 2 weeks.
        </>
      )}
    </Typography>
  ) : null
}
