export default {
  gridFilterInputLabel: 'Value',
  appsList: 'Valera Apps',
  InfiniteScroll: {
    allLoaded: 'All data has been loaded',
  },
  UserAccount: {
    formTitle: 'Update Account Information',
    accountFormMenuItemLabel: 'Account',
    introduction: 'Hello, {{name}}',
    iconTitle: 'Account Menu',
  },
  UserAccountForm: {
    updateSuccess: 'Your account settings were updated.',
    currentPassword: 'Current Password',
    proposedPassword: 'New Password',
    repeatProposedPassword: 'Repeat New Password',
    passwordsDoNotMatch: 'Passwords do not match',
    mustContainNumber: '{{field}} must contain a number.',
    mustContainSpecialCharacter: '{{field}} must contain a special character.',
    mustContainUppercase: '{{field}} must contain an uppercase character.',
    mustContainLowercase: '{{field}} must contain a lowercase character.',
    updatePasswordFailure: 'Unable to update password.',
    updatePasswordSuccess: 'Your password was successfully updated.',
    incorrectCurrentPassword: 'Your current password is incorrect.',
  },
  FileUpload: {
    allowedFileTypes: 'Allowed File Types: {{fileTypes}}. ',
    placeholder: 'Click to upload a file',
    dialogTitle: 'Upload a file for {{field}}',
    invalidFileName: 'Invalid file name.',
    invalidCharacters:
      'The following characters are not allowed in a file name: # % & $ < > * ? \\ / { } $ ! \' " + ` | = : @ ',
    invalidStartOfFile:
      'The start of the file needs to be an alphabetical or numeric character. ',
    invalidEndOfFile:
      'The end of the file needs to be an alphabetical, numeric, or parentheses character. ',
    invalidEmptyFileName: 'A file name is required. ',
    uploadFailed: 'Failed to upload file. ',
    fileUploadTotal: '{{complete}} of {{total}} uploaded',
    maxFiles: '(max {{count}})',
    helperText: '<click>Click to upload</click> or drag and drop',
    validationText: '{{accept, list(type: disjunction)}}',
    hasErrorsTitle: 'There are one or more errors',
    maxSize: 'max {{maxSize, bytes}}',
    minSize: 'min {{minSize, bytes}}',
    'file-invalid-type': 'Invalid file type. ',
    'file-too-large': 'The file is too large. ',
    'file-too-small': 'The file is too small. ',
    tooManyFiles_one: 'Only one file is allowed. ',
    tooManyFiles_other: 'A maximum of {{count}} files are allowed. ',
  },
  authMonitor: {
    signedOut: 'You have successfully signed out.',
    sessionExpired: 'Your session has expired.',
    ctaSignBackIn: 'Sign Back In',
    inactivityWarningTitle: 'Inactivity Warning',
    inactivityWarningMessage:
      'You will be signed out in {{seconds}} seconds if you remain inactive.',
  },
  notificationsMoreInfo: 'More Info',
  persistentDataGrid: {
    defaultView: 'Default View',
    isDefault: 'Is Default',
    setAsDefault: 'Set as Default',
    editView: 'Edit View',
    presetViewsGroupLabel: 'Preset Views',
    savedViewsGroupLabel: 'Your Saved Views',
    viewSavedSuccess: 'View "{{name}}" was saved',
    createNewSelectionOption: 'Create New "{{name}}" View',
    savedViewsTextFieldLabel: 'Saved Views',
    savedViewsTextFieldPlaceholder: 'Select a view',
    viewDialogTitleAdd: 'Add New Grid View',
    viewDialogTitleEdit: 'Edit {{name}} View',
    viewDialogNameIsEmptyErr: 'Name is required.',
    viewDialodNameIsNotUniqueErr: 'A view with name "{{name}}" already exists.',
  },
  editableDataGrid: {
    actionsColumn: 'Actions',
    genericInvalidRowMessage: 'There is an issue with this row.',
  },
  removeSort: 'Remove',
  ascendingString: 'A-Z',
  descendingString: 'Z-A',
  ascendingNumber: 'Number',
  descendingNumber: 'Number',
  ascendingDate: 'Date',
  descendingDate: 'Date',
  ascendingCount: 'Count',
  descendingCount: 'Count',
  CommentAuthor: 'By: {{author}}',
  CommentsList: {
    title: 'Previous Comments',
    noComments: 'No previous comments.',
  },
  Protected: {
    title: 'Access Denied',
    message:
      'You do not have permission to view this page. Please email provider-support@valerahealth.com to request access.',
    description: 'You need "{{Permission}}" access in order to view this page.',
  },
  export: 'Export',
  density: 'Density',
  emailSupport: 'Email Support',
  PatientTypeAhead: {
    placeholder: 'Type to search patients',
    optionsLoadFail: 'There was in error loading patients.',
    hasMorePrimaryText_one: '+ {{count}} more result',
    hasMorePrimaryText_other: '+ {{count}} more results',
    hasMoreSecondaryText: 'Type to further contrain results',
  },
  CalendarDensityOption: {
    COMPACT: 'Compact',
    STANDARD: 'Standard',
    COMFORTABLE: 'Comfortable',
  },
}
