/*This is an auto generated file. Do not alter directly*/
export enum Permission {
  Appointment_Create = 'Appointment:create',
  Appointment_CreateOwn = 'Appointment:create-own',
  Appointment_Delete = 'Appointment:delete',
  Appointment_DeleteOwn = 'Appointment:delete-own',
  Appointment_Read = 'Appointment:read',
  Appointment_ReadOwn = 'Appointment:read-own',
  Appointment_Update = 'Appointment:update',
  Appointment_UpdateOwn = 'Appointment:update-own',
  AuditEvent_Read = 'AuditEvent:read',
  AuditEvent_ReadOwn = 'AuditEvent:read-own',
  AuditEvent_Resync = 'AuditEvent:resync',
  EligibilityVerification_Execute = 'EligibilityVerification:execute',
  EligibilityVerification_Read = 'EligibilityVerification:read',
  EncryptedData_Encrypt = 'EncryptedData:encrypt',
  EncryptedData_Externalaccountpassword = 'EncryptedData:externalaccountpassword',
  EncryptedData_ExternalaccountpasswordOwn = 'EncryptedData:externalaccountpassword-own',
  EncryptedData_Ssn = 'EncryptedData:ssn',
  EncryptedData_SsnOwn = 'EncryptedData:ssn-own',
  GlobalTracks_Assign = 'GlobalTracks:assign',
  GlobalTracks_Create = 'GlobalTracks:create',
  GlobalTracks_Delete = 'GlobalTracks:delete',
  GlobalTracks_Read = 'GlobalTracks:read',
  GlobalTracks_Update = 'GlobalTracks:update',
  InsurancePlan_Create = 'InsurancePlan:create',
  InsurancePlan_Delete = 'InsurancePlan:delete',
  InsurancePlan_Read = 'InsurancePlan:read',
  InsurancePlan_Update = 'InsurancePlan:update',
  InsurancePlanParticipant_Create = 'InsurancePlanParticipant:create',
  InsurancePlanParticipant_Delete = 'InsurancePlanParticipant:delete',
  InsurancePlanParticipant_Read = 'InsurancePlanParticipant:read',
  InsurancePlanParticipant_Update = 'InsurancePlanParticipant:update',
  LandingPage_CmCalendar = 'LandingPage:cm-calendar',
  LandingPage_CmCaseload = 'LandingPage:cm-caseload',
  LandingPage_CmPatientsearch = 'LandingPage:cm-patientsearch',
  LandingPage_CmTasks = 'LandingPage:cm-tasks',
  Patient_Create = 'Patient:create',
  Patient_Delete = 'Patient:delete',
  Patient_Describe = 'Patient:describe',
  Patient_Read = 'Patient:read',
  Patient_ReadAssigned = 'Patient:read-assigned',
  Patient_ReadOwn = 'Patient:read-own',
  Patient_ReadSupervisee = 'Patient:read-supervisee',
  Patient_Update = 'Patient:update',
  Patient_UpdateAssigned = 'Patient:update-assigned',
  Patient_UpdateOwn = 'Patient:update-own',
  Patient_UpdateSupervisee = 'Patient:update-supervisee',
  PatientIntegration_Read = 'PatientIntegration:read',
  PatientIntegration_Update = 'PatientIntegration:update',
  Payer_Create = 'Payer:create',
  Payer_Delete = 'Payer:delete',
  Payer_Read = 'Payer:read',
  Payer_Update = 'Payer:update',
  PayerEnrollment_Create = 'PayerEnrollment:create',
  PayerEnrollment_Delete = 'PayerEnrollment:delete',
  PayerEnrollment_Read = 'PayerEnrollment:read',
  PayerEnrollment_Update = 'PayerEnrollment:update',
  Provider_Create = 'Provider:create',
  Provider_Delete = 'Provider:delete',
  Provider_Read = 'Provider:read',
  Provider_Update = 'Provider:update',
  Provider_UpdateOwn = 'Provider:update-own',
  Provider_UpdateSupervisee = 'Provider:update-supervisee',
  ProviderClinical_Update = 'ProviderClinical:update',
  ProviderCredentialing_Delete = 'ProviderCredentialing:delete',
  ProviderCredentialing_DeleteOwn = 'ProviderCredentialing:delete-own',
  ProviderCredentialing_Read = 'ProviderCredentialing:read',
  ProviderCredentialing_ReadOwn = 'ProviderCredentialing:read-own',
  ProviderCredentialing_Update = 'ProviderCredentialing:update',
  ProviderCredentialing_UpdateOwn = 'ProviderCredentialing:update-own',
  ProviderDocument_Create = 'ProviderDocument:create',
  ProviderDocument_CreateOwn = 'ProviderDocument:create-own',
  ProviderDocument_Delete = 'ProviderDocument:delete',
  ProviderDocument_DeleteOwn = 'ProviderDocument:delete-own',
  ProviderDocument_Read = 'ProviderDocument:read',
  ProviderDocument_ReadOwn = 'ProviderDocument:read-own',
  ProviderDocument_Update = 'ProviderDocument:update',
  ProviderDocument_UpdateOwn = 'ProviderDocument:update-own',
  ProviderEmployment_Create = 'ProviderEmployment:create',
  ProviderEmployment_Read = 'ProviderEmployment:read',
  ProviderEmployment_Update = 'ProviderEmployment:update',
  ProviderIdentifier_Update = 'ProviderIdentifier:update',
  ProviderLicenseRequest_Create = 'ProviderLicenseRequest:create',
  ProviderLicenseRequest_Delete = 'ProviderLicenseRequest:delete',
  ProviderLicenseRequest_Read = 'ProviderLicenseRequest:read',
  ProviderLicenseRequest_ReadOwn = 'ProviderLicenseRequest:read-own',
  ProviderLicenseRequest_Update = 'ProviderLicenseRequest:update',
  ProviderMetrics_Read = 'ProviderMetrics:read',
  ProviderMetrics_ReadOwn = 'ProviderMetrics:read-own',
  ProviderProfessionalHistory_Create = 'ProviderProfessionalHistory:create',
  ProviderProfessionalHistory_CreateOwn = 'ProviderProfessionalHistory:create-own',
  ProviderProfessionalHistory_Delete = 'ProviderProfessionalHistory:delete',
  ProviderProfessionalHistory_DeleteOwn = 'ProviderProfessionalHistory:delete-own',
  ProviderProfessionalHistory_Read = 'ProviderProfessionalHistory:read',
  ProviderProfessionalHistory_ReadOwn = 'ProviderProfessionalHistory:read-own',
  ProviderProfessionalHistory_Update = 'ProviderProfessionalHistory:update',
  ProviderProfessionalHistory_UpdateOwn = 'ProviderProfessionalHistory:update-own',
  ProviderVerifiedDocument_Create = 'ProviderVerifiedDocument:create',
  ProviderVerifiedDocument_Delete = 'ProviderVerifiedDocument:delete',
  ProviderVerifiedDocument_Read = 'ProviderVerifiedDocument:read',
  ProviderVerifiedDocument_ReadOwn = 'ProviderVerifiedDocument:read-own',
  ProviderVerifiedDocument_Update = 'ProviderVerifiedDocument:update',
  Reports_Read = 'Reports:read',
  Schedule_Create = 'Schedule:create',
  Schedule_CreateOwn = 'Schedule:create-own',
  Schedule_Delete = 'Schedule:delete',
  Schedule_DeleteOwn = 'Schedule:delete-own',
  Schedule_Read = 'Schedule:read',
  Schedule_ReadOwn = 'Schedule:read-own',
  Schedule_Update = 'Schedule:update',
  Schedule_UpdateOwn = 'Schedule:update-own',
  Task_Create = 'Task:create',
  Task_Delete = 'Task:delete',
  Task_Read = 'Task:read',
  Task_Update = 'Task:update',
  Tenant_Create = 'Tenant:create',
  Tenant_Delete = 'Tenant:delete',
  Tenant_Read = 'Tenant:read',
  Tenant_Update = 'Tenant:update',
  Tenant_UpdateConfig = 'Tenant:updateConfig',
  TreatmentDocument_Create = 'TreatmentDocument:create',
  TreatmentDocument_Delete = 'TreatmentDocument:delete',
  TreatmentDocument_Read = 'TreatmentDocument:read',
  TreatmentDocument_Update = 'TreatmentDocument:update',
  User_Create = 'User:create',
  User_CreateWithgoogle = 'User:create-withgoogle',
  User_Delete = 'User:delete',
  User_Describe = 'User:describe',
  User_Read = 'User:read',
  User_ReadOwn = 'User:read-own',
  User_Update = 'User:update',
  User_UpdateOwn = 'User:update-own',
}
