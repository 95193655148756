import { ReactNode, useEffect, useMemo } from 'react'
import {
  checkPermission,
  Permission,
  selectUser,
} from '@valerahealth/redux-auth'
import { AppointmentFragment } from '@valerahealth/rtk-query'
import { FormProvider, useForm } from '@valerahealth/ui-components/form'
import { useReduxSelector } from '../../reducer'
import { FormType } from './formType'

export const AppointmentFormProvider = ({
  defaultValues,
  appointment,
  children,
}: {
  defaultValues: FormType
  appointment?: AppointmentFragment | null | undefined
  children: ReactNode
}) => {
  const canCreateAny = useReduxSelector((state) =>
    checkPermission(state, Permission.Appointment_Create),
  )
  const ownProviderId = useReduxSelector(selectUser)?.providerId || null

  const initialFormValue = useMemo(
    () => ({
      ...defaultValues,
      // if editing an existing appointment keep provider the same,
      // if adding and you have the ability to create an appointment for any user use the default,
      // else you only have create_own to have made it this far and use the users own provider id.
      providerId:
        appointment || canCreateAny ? defaultValues.providerId : ownProviderId,
    }),
    [defaultValues, appointment, canCreateAny, ownProviderId],
  )

  const methods = useForm<FormType>({
    defaultValues: initialFormValue,
  })

  useEffect(() => {
    methods.reset(initialFormValue)
  // We only want to reset form if appointment changes, hence linter disable
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment])

  return <FormProvider {...methods}>{children}</FormProvider>
}
