import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  NavLink,
  Navigate,
  Outlet,
  useParams,
  useNavigate,
} from 'react-router-dom'
import './style.less'
import {
  IconButton,
  MenuItem,
  PopupMenu,
  CenteredSpinner,
  Typography,
  useMediaQuery,
  useNotify,
  useTheme,
  Stack,
  Box,
  Button,
} from '@valerahealth/ui-components'
import { useTranslation } from '@valerahealth/ui-translation'
import {
  AppointmentFragment,
  CMAppointmentRef,
  careManagerApi,
  patientRegistrationApi,
  schedulingApi,
} from '@valerahealth/rtk-query'
import {
  MoreVert,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@valerahealth/ui-components/icons'
import { type RootState, useReduxDispatch, useReduxSelector } from 'redux/store'
import {
  ApptSummry,
  type TreatmentRoomRouteContext,
} from 'components/utilities/treatments'
import {
  configProgramSettingsSelector,
  patientNavigationLayoutSelector,
  userAdminSelector,
} from 'redux/selectors'
import {
  selectTreatment,
  unselectTreatment,
} from '../../redux/actions/treatmentRoom'
import { toggleSidebar } from '../../redux/actions/globalActions'
import TreatmentHeader from './TreatmentHeader'
import TreatmentSidebar from '../TreatmentSidebar'

const { useGetNextLastApptByTreatmentIdQuery } = schedulingApi

const toApptSummary = (
  appt?:
    | Pick<AppointmentFragment, '_id' | 'startDate' | 'endDate'>
    | CMAppointmentRef
    | null,
): ApptSummry | undefined => {
  if (!appt) return undefined
  if ('date' in appt) {
    return {
      isLegacyAppt: true,
      id: appt.id,
      start: new Date(appt.date),
    }
  }
  return {
    isLegacyAppt: false,
    id: appt._id,
    start: new Date(appt.startDate),
    end: new Date(appt.endDate),
  }
}

const now = new Date().toISOString()
export const SIDEBAR_WIDTH = 33

const RightHeaderMenu = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <PopupMenu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      button={
        <IconButton
          size="small"
          aria-label="valera apps"
          aria-controls="menu-appbar"
          aria-haspopup="true"
        >
          <MoreVert />
        </IconButton>
      }
    >
      <MenuItem
        onClick={() => {
          navigate('plan')
        }}
      >
        {t('tracks')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate('data')
        }}
      >
        {t('data')}
      </MenuItem>
    </PopupMenu>
  )
}

export default function TreatmentRoom() {
  const { t } = useTranslation()
  const dispatch = useReduxDispatch()
  const notify = useNotify()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.up(1400))
  const treatmentId = useParams<{ treatmentId: string }>().treatmentId!

  const configProgramSettings = useReduxSelector(configProgramSettingsSelector)
  const useSchedulingAppt =
    configProgramSettings?.data?.appointmentSettings?.flags?.useSchedulingAppt
  const { goToCaseload } = useSelector((state: RootState) => state.caseload)
  const { confirmRedirection, openSidebar } = useSelector(
    (state: RootState) => state.global,
  )
  const { permissions } = useReduxSelector(userAdminSelector)

  useEffect(() => {
    dispatch(selectTreatment(treatmentId))
    return () => {
      dispatch(unselectTreatment())
    }
  }, [dispatch, treatmentId])

  const {
    nextAppt,
    lastAppt,
    error: getApptError,
  } = useGetNextLastApptByTreatmentIdQuery(
    {
      id: treatmentId,
      now,
    },
    {
      skip: !useSchedulingAppt,
      selectFromResult: ({ data, isLoading, isUninitialized, error }) => ({
        nextAppt: data?.getNextApptByPatientId?.results?.length
          ? data?.getNextApptByPatientId?.results[0]
          : undefined,
        lastAppt: data?.getLastApptByPatientId?.results?.length
          ? data?.getLastApptByPatientId?.results[0]
          : undefined,
        isLoading: isLoading || isUninitialized,
        error,
      }),
    },
  )

  const {
    data: treatment,
    isLoading: isTreatmentLoading,
    error: getTreatmentError,
  } = careManagerApi.useGetTreatmentQuery(
    { treatmentId },
    {
      selectFromResult: ({ data, isLoading, isUninitialized, error }) => ({
        data,
        isLoading: isLoading || isUninitialized,
        error,
      }),
    },
  )

  const {
    data: treatmentProfile,
    isLoading: isTreatmentProfileLoading,
    error: getTreatmentProfileError,
  } = careManagerApi.useGetPatientProfileByTreatmentIdQuery(
    { id: treatmentId },
    {
      selectFromResult: ({ data, isLoading, isUninitialized, error }) => ({
        data,
        isLoading: isLoading || isUninitialized,
        error,
      }),
    },
  )

  const {
    data: patient,
    error: getPatientError,
    isLoading: isPatientLoading,
  } = patientRegistrationApi.useGetPatientByTreatmentIdQuery(
    { treatmentId },
    {
      selectFromResult: ({ data, error, isLoading }) => ({
        data: data?.getPatientByTreatmentId!,
        error,
        isLoading,
      }),
    },
  )

  const isPageLoading =
    isTreatmentLoading || isTreatmentProfileLoading || isPatientLoading
  const isPageError =
    getTreatmentError || getTreatmentProfileError || getPatientError

  useEffect(() => {
    if (getTreatmentError) {
      const message =
        'data' in getTreatmentError ? getTreatmentError.data?.message : ''
      notify({
        message: message || 'Could not load Patient Treatment Record',
        severity: 'error',
      })
      console.error(getTreatmentError)
    }

    if (getPatientError) {
      const message = 'data' in getPatientError ? getPatientError.message : ''
      notify({
        message: message || 'Could not load Patient EMR Status',
        severity: 'error',
      })
      console.error(getPatientError)
    }

    if (getTreatmentProfileError) {
      const message =
        'data' in getTreatmentProfileError
          ? getTreatmentProfileError.data.message
          : ''
      notify({
        message: message || 'Could not load Patient Profile Record',
        severity: 'error',
      })
      console.error(getTreatmentProfileError)
    }
    if (getApptError) {
      notify({
        message:
          getApptError.message ||
          'An error occurred while retrieving the previous and next appointments for the Patient',
        severity: 'error',
      })
      console.error(getApptError)
    }
  }, [
    getTreatmentError,
    getPatientError,
    getTreatmentProfileError,
    getApptError,
    notify,
  ])

  // only used inside a conditional that guarantees the route context is loaded
  const routeContext: TreatmentRoomRouteContext = {
    treatmentId,
    treatment: treatment!,
    treatmentProfile: treatmentProfile!,
    patient,
    nextAppt: toApptSummary(
      useSchedulingAppt ? nextAppt : treatment?.nextAppointment,
    ),
    lastAppt: toApptSummary(
      useSchedulingAppt ? lastAppt : treatment?.lastAppointment,
    ),
  }

  const {
    timelineHidden,
    basicInfoHidden,
    billingHidden,
    alertsHidden,
    appointmentsHidden,
    tracksHidden,
    noteHidden,
    careTeamHidden,
    documentsHidden,
    dataHidden,
  } = useReduxSelector(patientNavigationLayoutSelector)

  return (
    <>
      {goToCaseload && <Navigate to="/caseload" replace />}
      <TreatmentHeader {...routeContext} />
      <Stack
        flexDirection="row"
        flexGrow={1}
        maxHeight="100%"
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <Box flex="2 2 100%">
          <Box
            component="nav"
            sx={{
              height: '4rem',
              display: 'flex',
              gap: 2,
              wrap: 'no-wrap',
              alignItems: 'center',
              p: '0 2rem 0 1rem',
              a: {
                color: (theme) => theme.palette.action.disabled,
              },
              'a.selected-sub-menu': {
                fontWeight: 'bold',
                color: (theme) => theme.palette.action.active,
              },
            }}
          >
            {!timelineHidden && (
              <NavLink
                to="timeline"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('Timeline')}
              </NavLink>
            )}

            {!basicInfoHidden && (
              <NavLink
                to="basic-info"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('basicInfo')}
              </NavLink>
            )}

            {!billingHidden && (
              <NavLink
                to="billing"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('billing')}
              </NavLink>
            )}
            {!alertsHidden && (
              <NavLink
                to="alerts"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('Alerts')}
              </NavLink>
            )}
            {!appointmentsHidden && (
              <NavLink
                to="appointments"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('appointments')}
              </NavLink>
            )}

            {!noteHidden && permissions?.accessToNotes && (
              <NavLink
                to="note"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('notes')}
              </NavLink>
            )}
            {!careTeamHidden && (
              <NavLink
                to="careteam"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('CareTeam')}
              </NavLink>
            )}

            {!documentsHidden && (
              <NavLink
                to="documents"
                className={({ isActive }) =>
                  isActive ? 'selected-sub-menu' : ''
                }
              >
                {t('documents')}
              </NavLink>
            )}

            {fullWidth ? (
              <>
                {!tracksHidden && (
                  <NavLink
                    to="plan"
                    className={({ isActive }) =>
                      isActive ? 'selected-sub-menu' : ''
                    }
                  >
                    {t('tracks')}
                  </NavLink>
                )}
                {!dataHidden && (
                  <NavLink
                    to="data"
                    className={({ isActive }) =>
                      isActive ? 'selected-sub-menu' : ''
                    }
                  >
                    {t('data')}
                  </NavLink>
                )}
              </>
            ) : (
              (treatment && !confirmRedirection && <RightHeaderMenu />) || ''
            )}
          </Box>
          <div className="treatment-room-container">
            {isPageLoading ? (
              <CenteredSpinner />
            ) : isPageError ? (
              <Typography>An error occured</Typography>
            ) : (
              <Outlet context={routeContext} />
            )}
          </div>
        </Box>

        <Box
          position="relative"
          flex="1 1 auto"
          display="flex"
          flexDirection="column"
          sx={
            openSidebar
              ? {
                  minWidth: '20rem',
                  maxWidth: '30rem',
                  flexBasis: '100%',
                }
              : undefined
          }
        >
          <Button
            size="small"
            color="secondary"
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              transform: 'translate(-100%,-100%) rotate(-90deg)',
              transformOrigin: 'bottom right',
              zIndex: 2,
              borderRadius: '5px 5px 0px 0px',
              whiteSpace: 'nowrap',
              lineHeight: 1.5,
              fontSize: '.675rem',
              textTransform: 'none',
            }}
            onClick={() => {
              dispatch(toggleSidebar())
            }}
            endIcon={openSidebar ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          >
            {openSidebar ? t('hideSidebar') : t('expandSidebar')}
          </Button>
          {openSidebar && <TreatmentSidebar routeContext={routeContext} />}
        </Box>
      </Stack>
    </>
  )
}
