import React, { useEffect, useState } from 'react'
import { Popup, Form as FormComponent } from '@valerahealth/ui-core'
import { connect } from 'react-redux'
import dStrings from '../../strings.json'
import {
  addUserMessage,
  editScheduleDate,
  displayConfirmation,
} from '../../redux/actions/globalActions'
import { useReduxSelector } from '../../redux/store'
import { configFlagsSelector } from '../../redux/selectors'
import CalendarDateIcon from '../../images/calendar_date.svg'
import CalendarTimeIcon from '../../images/calendar_time.svg'
import { loadProgramProviders } from '../../redux/actions/programActions'
import { CoveringSelect, handleScheduleError } from './utilities'
import { dateWithTimeZone } from '../utilities'
import { createOutOfOffice, updateOutOfOffice } from '../../api/providerAPI'
import { getErrorMessage } from '../../redux/actions/utilities'

function EditDatesPopup({
  id,
  outOfOfficeData = {},
  user,
  providers,
  handleClose,
  handleChange,
  addUserMessage,
  loadProgramProviders,
  displayConfirmation,
}) {
  const {oooCoveringProviderActive} = useReduxSelector(configFlagsSelector)

  const [disabledSaveDatesBtn, setDisabledSaveDatesBtn] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (!providers) {
      loadProgramProviders()
    }
  }, [providers])

  const saveEditDates = (data, force) => {
    setDisabledSaveDatesBtn(true)
    const sd = dateWithTimeZone(data.startDate, user.notifications.timeZone)
    const ed = dateWithTimeZone(data.endDate, user.notifications.timeZone)

    const dataToSend = {
      startDate: sd,
      endDate: ed,
      coveringProviderId: data.coveringProviderId || null,
      forceOOO: force && force.forceOOO,
      forceCoveringProvider: force && force.forceCoveringProvider,
    }

    const cb = () => {
      handleClose()

      addUserMessage(
        'success',
        outOfOfficeData.id
          ? dStrings.oooSuccessfullyUpdated
          : dStrings.oooSuccessfullyAdded,
      )
      handleChange && handleChange(data)
    }

    const errorCb = (res) => {
      const err = getErrorMessage(res)

      setDisabledSaveDatesBtn(false)
      if (err && err.message) {
        handleScheduleError({
          key: err.message,
          error: err.error,
          data,
          user,
          providers,
          displayConfirmation,
          handleSaveEditDates: saveEditDates,
          addUserMessage,
        })
      }
    }

    if (outOfOfficeData.id) {
      updateOutOfOffice(id, outOfOfficeData.id, dataToSend)
        .then(cb)
        .catch(errorCb)
    } else {
      createOutOfOffice(id, dataToSend).then(cb).catch(errorCb)
    }

    // editScheduleDate({ dates, cb, errorCb, id });
  }

  const getData = () => {
    const timeZone = user && user.notifications && user.notifications.timeZone

    const { startDate } = outOfOfficeData

    const { endDate } = outOfOfficeData

    const { coveringProviderId } = outOfOfficeData
    const startTime = outOfOfficeData.startDate
      ? `${startDate.getHours()}:${startDate.getMinutes()}`
      : '00:00'
    const endTime = outOfOfficeData.endDate
      ? `${endDate.getHours()}:${endDate.getMinutes()}`
      : '00:00'

    startDate && startDate.setHours(0, 0, 0, 0)
    endDate && endDate.setHours(0, 0, 0, 0)

    return {
      startDate,
      endDate,
      startTime,
      endTime,
      coveringProviderId,
      status: outOfOfficeData.status,
    }
  }

  const checkData = () => {
    if (data.active === false) {
      saveEditDates({ active: false })
      return
    }

    let hasError = false
    const _errors = {}
    const startDate = data.startDate && new Date(data.startDate.getTime())
    const endDate = data.endDate && new Date(data.endDate.getTime())

    if (!startDate && !endDate) {
      saveEditDates({ active: false })
      return
    }

    if (!startDate) {
      hasError = true
      _errors.startDate = true
    }

    if (!endDate) {
      hasError = true
      _errors.endDate = true
    }

    if (!data.startTime) {
      hasError = true
      _errors.startTime = true
    }

    if (!data.endTime) {
      hasError = true
      _errors.endTime = true
    }

    if (hasError) {
      setErrors(_errors)
      return
    }

    setErrors({})

    const [startHour, startMinute] = data.startTime.split(':')
    const [endHour, endMinute] = data.endTime.split(':')

    startDate.setHours(startHour, startMinute)
    endDate.setHours(endHour, endMinute)

    if (startDate >= endDate) {
      addUserMessage('error', dStrings.endDateAfterStartDate)
      return
    }

    saveEditDates({
      startDate,
      endDate,
      active: true,
      coveringProviderId: data.coveringProviderId || undefined,
    })
  }

  const [data, setData] = useState(getData)
  

  return (
    <Popup
      title={dStrings.editOooDates}
      onClosed={handleClose}
      buttons={[
        {
          text: dStrings.cancel,
          onClick: handleClose,
          type: 'link',
          style: { width: '110px' },
        },
        {
          text: dStrings.save,
          disable: disabledSaveDatesBtn,
          type: 'primary',
          style: { width: '110px' },
          onClick: checkData,
        },
      ]}
    >
      <div className="edit-date-popup">
        <div className="edit-date-popup-title title">
          <b>{dStrings.startTime}</b>
          <span style={{ color: '#D24667' }}>*</span>
        </div>
        <div className="edit-date-popup-line">
          <FormComponent
            data={data}
            handleDataChange={(_data) => {
              if (data.hasChanged) return
              setData({
                ...data,
                active: true,
                startDate: _data.startDate || undefined,
                startTime: _data.startTime,
              })
            }}
            displayInvalid={errors.startDate || errors.startTime}
            fields={[
              {
                key: 'startDate',
                text: (
                  <div className="ooo-date">
                    {' '}
                    <CalendarDateIcon />
                  </div>
                ),
                type: 'date',
                disabled: data.status === 'current',
                required: true,
                invalid: errors.startDate,
                invalidMessage: errors.startDate && dStrings.invalidDate,
              },
              {
                key: 'startTime',
                text: (
                  <div className="ooo-date">
                    {' '}
                    <CalendarTimeIcon />
                  </div>
                ),
                type: 'time',
                disabled: data.status === 'current',
                required: true,
                invalid: errors.startTime,
                invalidMessage: errors.startTime && dStrings.invalidTime,
              },
            ]}
          />
          {data.status !== 'current' && (
            <div
              className="link-click"
              onClick={() => {
                const _data = {
                  ...data,
                  hasChanged: true,
                  startDate: undefined,
                  startTime: '00:00',
                }

                setData(_data)
                setTimeout(() => {
                  setData({ ..._data, hasChanged: false })
                }, 500)
              }}
            >
              {dStrings.clear}
            </div>
          )}
        </div>

        <div className="edit-date-popup-title title">
          <b>{dStrings.endTime}</b>
          <span style={{ color: '#D24667' }}>*</span>
        </div>
        <div className="edit-date-popup-line">
          <FormComponent
            data={data}
            handleDataChange={(_data) => {
              if (data.hasChanged) return
              setData({
                ...data,
                active: true,
                endDate: _data.endDate || undefined,
                endTime: _data.endTime,
              })
            }}
            displayInvalid={errors.endDate || errors.endTime}
            fields={[
              {
                key: 'endDate',
                text: (
                  <div className="ooo-date">
                    {' '}
                    <CalendarDateIcon />
                  </div>
                ),
                type: 'date',
                required: true,
                invalid: errors.endDate,
                invalidMessage: errors.endDate && dStrings.invalidDate,
              },
              {
                key: 'endTime',
                text: (
                  <div className="ooo-date">
                    {' '}
                    <CalendarTimeIcon />
                  </div>
                ),
                type: 'time',
                required: true,
                invalid: errors.endTime,
                invalidMessage: errors.endTime && dStrings.invalidTime,
              },
            ]}
          />
          <div
            className="link-click"
            onClick={() => {
              const _data = {
                ...data,
                hasChanged: true,
                endDate: undefined,
                endTime: '00:00',
              }

              setData(_data)
              setTimeout(() => {
                setData({ ..._data, hasChanged: false })
              }, 500)
            }}
          >
            {dStrings.clear}
          </div>
        </div>

        {oooCoveringProviderActive && (
          <>
            <div className="edit-date-popup-title">
              <b>{dStrings.coveringUser}</b>
            </div>
            <div className="edit-date-popup-explanation">
              {dStrings.coveringUserExpl}
            </div>
            <CoveringSelect
              providers={providers}
              coveringProviderId={(data && data.coveringProviderId) || null}
              handleChange={(t) => {
                setData({
                  ...data,
                  coveringProviderId: t.value,
                })
              }}
            />
          </>
        )}
      </div>
    </Popup>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    providers:
      state.program.careTeam &&
      state.program.careTeam.allIds.map(
        (id) => state.program.careTeam.byId[id],
      ),
  }
}

const mapDispatchToProps = {
  addUserMessage,
  loadProgramProviders,
  editScheduleDate,
  displayConfirmation,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDatesPopup)
