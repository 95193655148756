import { useMemo } from 'react'
import {
  type EventProps,
  CALENDAR_TIMESLOT_HEIGHT_PX,
  useCalendarContext,
} from '@valerahealth/ui-components/features/Calendar'
import { AppointmentStatus } from '@valerahealth/rtk-query'
import { Box, Typography, Stack } from '@valerahealth/ui-components'
import { format } from '@valerahealth/ui-components/utils/date'
import { AppointmentStatusIcon } from '../../utilities/Icons'
import {
  CalendarEventType,
  type AppointmentEventType,
  ScheduleEventType,
} from './Calendar.type'
import { useTranslation } from '../../locales'
import {
  CARD_GUTTER_WIDTH_REMS,
  BACKGROUND_EVENT_BORDER_PX,
  EVENT_BORDER_PX,
  EVENT_GAP,
} from '../../utilities/constants'
import { useAppointmentDetailsPopup } from './useAppointmentDetailsPopup'
import { ADMINISTRATIVE_APPOINTMENTS_SET } from '../../utilities'

export function ApptEventWeek({ event }: { event: AppointmentEventType }) {
  const resource = event.resource!
  const [t] = useTranslation()

  const {selectCalendarState} = useCalendarContext()
  const {calendarDensity} = selectCalendarState()

  const { setAnchorEl, AppointmentDetailsPopup } =
    useAppointmentDetailsPopup(event)

  const patientName = resource.patient?.display

  const eventDurationText = useMemo(() => {
    if (event.start && event.end) {
      const isMultiDay = event.start.getDay() !== event.end.getDay()
      const formatStr = isMultiDay ? 'EEE p' : 'p'
      return `${format(event.start, formatStr)} - ${format(
        event.end,
        formatStr,
      )}`
    }
    return ''
  }, [event.start, event.end])

  const durationInMinutes = useMemo(() => {
    if (event.start && event.end) {
      const diff = event.end.getTime() - event.start.getTime()
      return diff / (1000 * 60)
    }
    return Number.MAX_SAFE_INTEGER
  }, [event.start, event.end])

  if (!resource) return null

  const isAbbreviated =
    durationInMinutes < 30 || event.start?.getDay() !== event.end?.getDay()
  const isAdministrativeAppt = ADMINISTRATIVE_APPOINTMENTS_SET.has(
    event.resource.serviceCategory,
  )
  const { isCanceled } = event.resource

  return (
    <>
      <Box
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '0.75rem',
        }}
      >
        <Stack
          component={Typography}
          direction="row"
          color="inherit"
          sx={{
            overflow: 'hidden',
            px: 0.5,
            // appts have 2px of border and they are 2px smaller than the timewindow to give spacing between appts
            height: `${
              CALENDAR_TIMESLOT_HEIGHT_PX * 0.25 -
              EVENT_BORDER_PX * 2 -
              EVENT_GAP * 2
            }px`,
            alignItems: 'center',
            fontSize: '1em',
            gap: 0.75,
            justifyContent: 'space-between',
            flexShrink: 0,
          }}
        >
          <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
            {resource.serviceTypeAcronym}
          </Box>
          <Box
            component="span"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              minWidth: 0,
              mr: 'auto',
            }}
          >
            {(durationInMinutes <= 30 || calendarDensity === 'COMPACT') && patientName}
          </Box>

          <AppointmentStatusIcon
            status={resource.status}
            sx={{
              fontSize: '1.25em',
            }}
            {...(resource.status === AppointmentStatus.Proposed
              ? {
                  color: 'inherit',
                  sx: { fontSize: '1.5em' },
                }
              : null)}
          />
        </Stack>
        {!isAbbreviated && (
          <Box
            sx={{
              ...(isCanceled
                ? {
                    textDecorationLine: 'line-through',
                  }
                : null),
              px: 0.5,
            }}
          >
            {durationInMinutes > 30 && !isAdministrativeAppt && calendarDensity !== 'COMPACT' && (
              <Typography
                sx={{
                  fontSize: '1em',
                  fontWeight: '600',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  minWidth: 0,
                }}
              >
                {patientName}
              </Typography>
            )}
            <Typography sx={{ fontSize: '1em' }}>
              {eventDurationText}
            </Typography>
            {durationInMinutes >= 60 && isCanceled && (
              <Typography sx={{ fontSize: '1em' }}>
                {t(`AppointmentStatus.${resource.status}`)}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {AppointmentDetailsPopup}
    </>
  )
}

const ScheduleBackgroundEvent = ({ event }: { event: ScheduleEventType }) => {
  const resource = event.resource!
  const { t } = useTranslation()
  const title = t(`ServiceCategoryCode.${resource.serviceCategoryCode}`)

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: resource.backgroundColor,
        borderRadius: '4px',
        overflow: 'hidden',
        border: `${BACKGROUND_EVENT_BORDER_PX}px solid ${resource.borderColor}`,
        margin: `0px ${EVENT_GAP}px`,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          height: `calc(100% + ${BACKGROUND_EVENT_BORDER_PX}px)`,
          width: `${CARD_GUTTER_WIDTH_REMS}rem`,
          backgroundColor: resource.borderColor,
          margin: '0px',
          top: 0,
          left: `-${BACKGROUND_EVENT_BORDER_PX}px`,
        }}
      >
        <Typography
          color="common.white"
          variant="h6"
          sx={{ fontSize: '1rem !important' }}
          textAlign="center"
        >
          {title[0]}
        </Typography>
      </Box>
    </Box>
  )
}

export function Event({
  event,
}: // The reset of the rbc props are typed wrong from the npm package, most props are only populated if the event spans multiple days, the only thing consistently populated it title
EventProps<CalendarEventType>) {
  return event.resource?.type === 'appointment' ? (
    <ApptEventWeek event={event as AppointmentEventType} />
  ) : (
    <ScheduleBackgroundEvent event={event as ScheduleEventType} />
  )
}
